import {
	addDoc,
	collection,
	deleteDoc,
	getDocs,
	orderBy,
	query,
	Timestamp,
	where,
} from "firebase/firestore";
import { db } from "..";

export interface GameLinkGetDTO {
	gameLink: string;
	companyName: string;
	expirationDate: Date;
}

export interface GameLinkCreateDTO {
	gameLink: string;
	companyName: string;
	expirationDate: Date;
	gameId: string
}

export async function deleteExpiredGameLinks() {
	try {
		// Retrieve expired game links
		const gameLinkCollectionRef = collection(db, "GameLinks");
		const filterGameLinksQuery = query(
			gameLinkCollectionRef,
			orderBy("expirationDate", "asc"),
			where("expirationDate", "<=", Timestamp.now())
		);
		const gameLinkDocs = await getDocs(filterGameLinksQuery);

		// Delete each expired game link document
		for (const gameLinkDoc of gameLinkDocs.docs) {
			const expirationDate = gameLinkDoc.data().expirationDate;
			// Convert the expirationDate Timestamp to a Date object
			const expirationDateAsDate = expirationDate.toDate();
			console.log("experaion date as date: " + expirationDateAsDate);
			if (expirationDateAsDate <= new Date()) {
				// The expiration date has passed, so delete the game link document
				const gameLinkDocRef = gameLinkDoc.ref;
				await deleteDoc(gameLinkDocRef);
			}
		}
		console.log(`Successfully deleted expired game links` + gameLinkDocs);
	} catch (error) {
		console.log(`Firestore could not delete expired game links\n ${error}`);
	}
}
export async function createGameLink(gameLinkDetails: GameLinkCreateDTO) {
	try {
		const gameLinkCollectionRef = collection(db, "GameLinks");
		await addDoc(gameLinkCollectionRef, {
			gameLink: gameLinkDetails.gameLink,
			companyName: gameLinkDetails.companyName,
			expirationDate: Timestamp.fromDate(gameLinkDetails.expirationDate),
			gameId: gameLinkDetails.gameId,
		});
		console.log(
			`Succesfully Create Game Link for Company: ${gameLinkDetails.companyName}`
		);
	} catch (error) {
		console.log(
			`Firestore could not Create Game Link for Company: ${gameLinkDetails.companyName}\n ${error}`
		);
	}
}

export async function getAllGameLinks() {
	try {
		const gameLinksRef = collection(db, "GameLinks");
		const sortedGameLinkQuery = query(
			gameLinksRef,
			orderBy("companyName", "asc")
		);
		const gameLinkDocs = await getDocs(sortedGameLinkQuery);
		const gameLinks: Array<GameLinkGetDTO> = [];
		for (const gameLinkDoc of gameLinkDocs.docs) {
			if (gameLinkDoc.data()) {
				const gameLinkDetails: GameLinkGetDTO = {
					gameLink: gameLinkDoc.data().gameLink,
					companyName: gameLinkDoc.data().companyName,
					expirationDate: gameLinkDoc.data().expirationDate,
				};
				gameLinks.push(gameLinkDetails);
			}
		}
		console.log(`Successfully Got all Game Link Details`);
		return gameLinks;
	} catch (error) {
		console.log(`Firestore could not Get All Game Link Details\n ${error}`);
	}
}

export async function getAllGameIds() {
	try {
		const gameLinksRef = collection(db, "GameLinks");
		const sortedGameLinkQuery = query(
			gameLinksRef,
			orderBy("companyName", "asc")
		);
		const gameLinkDocs = await getDocs(sortedGameLinkQuery);
		const gameIds: Array<string> = [];
		for (const gameLinkDoc of gameLinkDocs.docs) {
			if (gameLinkDoc.data().gameId) {
				gameIds.push(gameLinkDoc.data().gameId);
			}
		}
		console.log(`Successfully Got all Game Link Ids`);
		return gameIds;
	} catch (error) {
		console.log(`Firestore could not Get All Game Ids Details\n ${error}`);
	}
}
