import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { Leaderboard } from "../components/Leaderboard";
import Navbar from "../components/Navbar";

export const LeaderboardPage = () => {
	const params = useParams();
	const gameId: string = params.gameId!;

	return (
		<>
			<section className="home">
				<Navbar gameId={gameId} isAdmin={false}></Navbar>
				<Box className="overflow-protection">
					<Leaderboard
						gameId={gameId}
						isRealtime={false}
					/>
				</Box>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</section>
		</>
	);
};
