import React, { useContext } from "react";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import FolderIcon from "@mui/icons-material/Folder";
import {
	createNumberOfMaxTries,
	getBackgroundColourGameState,
	getGuessingPremadeFactsOnly,
	getLogoColourGameState,
	getNumberOfFactsState,
	getNumberOfMaxTries,
	getVisibleScoreState,
	setBackgroundColourGameState,
	setLogoColourGameState,
	updateGuessingPremadeFactsOnly,
	updateNumberOfFactsState,
	updateNumberOfMaxTries,
	updateVisibleScoreState,
} from "../services/GameStatesService";
import { storage } from "..";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import SettingService from "../services/SettingService";
import { useNavigate, useParams } from "react-router-dom";
import { LangContext } from "../context/lang";

export const SettingsPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const types = ["image/png", "image/jpeg"];
	const regex = /^[0-9\b]+$/;

	const params = useParams();
	const gameId: string = params.gameId!;

	const navigate = useNavigate();

	const [isScoreVisible, setIsScoreVisible] = useState(false);
	const [isGuessingPremadeOnly, setIsGuessingPremadeOnly] = useState(false);
	const [companyLogoURL, setCompanyLogoURL] = useState("");
	const [teamBuildLogoURL, setTeamBuildLogoURL] = useState("");
	const [hasRetreivedLogo, setHasRetreivedLogo] = useState(false);
	const [numMaxTries, setNumMaxTries] = useState(0);
	const [backgroundColour, setBackgroundColour] = useState("");
	const [logoColour, setLogoColour] = useState("");
	const [isUpdatingBackgroundColour, setIsUpdatingBackgroundColour] = useState(false);
	const [isUpdatingLogoColour, setIsUpdatingLogoColour] = useState(false);
	const [numberOfFacts, setNumberOfFacts] = useState("");
	const [isUpdatingNumberOfFacts, setIsUpdatingNumberOfFacts] = useState(false);

	function handleBackClick() {
		navigate(`/${gameId}`);
	}

	const handleNumberOfFacts = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberPlayers = e.target.value;

		if (numberPlayers === "" || regex.test(numberPlayers)) {
			setNumberOfFacts(numberPlayers);
			setIsUpdatingNumberOfFacts(true)
		}
	};

	const updateNumberOfFacts = async () => {
		updateNumberOfFactsState(gameId, parseInt(numberOfFacts));
		setIsUpdatingNumberOfFacts(false)
	};

	const handleChangeVisibleScoreSwitch = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		updateVisibleScoreState(gameId, event.target.checked);
		setIsScoreVisible(event.target.checked);
	};

	const handleChangeGuessingPremadeOnly = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		updateGuessingPremadeFactsOnly(gameId, event.target.checked);
		setIsGuessingPremadeOnly(event.target.checked);
	};

	const handleTeamBuildingLogoUpdate = async (e: {
		target: { files: any };
	}) => {
		const file = e.target.files[0];

		if (file && types.includes(file.type)) {
			await uploadTeamBuildingLogo(file);
		} else {
			return;
		}
	};

	const handleCompanyLogoUpdate = async (e: { target: { files: any } }) => {
		const file = e.target.files[0];

		if (file && types.includes(file.type)) {
			await uploadCompanyLogo(file);
		} else {
			return;
		}
	};

	const handleNumMaxTries = async (e: SelectChangeEvent) => {
		const numMaxTries = e.target.value as unknown as number;
		setNumMaxTries(numMaxTries);
		updateNumberOfMaxTries(gameId, numMaxTries);
	};

	const handleBackgroundColour = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const backgroundColour = e.target.value as string;
		setBackgroundColour(backgroundColour);
		setIsUpdatingBackgroundColour(true)
	};

	const handleLogoColour = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const logoColour = e.target.value as string;
		setLogoColour(logoColour);
		setIsUpdatingLogoColour(true)
	};

	const updateBackgroundColour = async () => {
		setBackgroundColourGameState(gameId, backgroundColour);
		setIsUpdatingBackgroundColour(false)
	};

	const updateLogoColour = async () => {
		setLogoColourGameState(gameId, logoColour);
		setIsUpdatingLogoColour(false)
	};

	async function uploadTeamBuildingLogo(file: any) {
		const storageRef = ref(storage, `teamBuilding-logos/${gameId}/${file}`);
		if (file) {
			const uploadResult = await uploadBytes(storageRef, file);
			const downloadURL = await getDownloadURL(uploadResult.ref);
			SettingService.setTeamBuildingLogo(gameId, downloadURL);
			setTeamBuildLogoURL(downloadURL);
			console.log("file: " + file);
			console.log("downlowd " + downloadURL);
		}
	}

	async function uploadCompanyLogo(file: any) {
		const storageRef = ref(storage, `company-logos/${gameId}/${file}`);
		if (file) {
			const uploadResult = await uploadBytes(storageRef, file);
			const downloadURL = await getDownloadURL(uploadResult.ref);
			SettingService.setCompanyLogo(gameId, downloadURL);
			setCompanyLogoURL(downloadURL);
			console.log("file: " + file);
			console.log("downlowd " + downloadURL);
		}
	}

	useEffect(() => {
		async function initializeNumberOfFacts() {
			const fetchedNumberOfFacts = await getNumberOfFactsState(gameId);
			if (fetchedNumberOfFacts) {
				setNumberOfFacts(fetchedNumberOfFacts);
			}
		}

		async function initializeIsScoreVisible() {
			const fetchedIsScoreVisible = await getVisibleScoreState(gameId);
			if (fetchedIsScoreVisible) {
				setIsScoreVisible(fetchedIsScoreVisible);
			}
		}

		async function initializeIsGuessingOnlyPremadeFacts() {
			const fetchedIsGuessingOnlyPremadeFacts = await getGuessingPremadeFactsOnly(gameId);
			if (fetchedIsGuessingOnlyPremadeFacts) {
				setIsGuessingPremadeOnly(fetchedIsGuessingOnlyPremadeFacts);
			}
		}

		async function initializeTeamBuildLogo() {
			try {
				const url = await SettingService.getTeamBuildingLogo(gameId);
				if (url) {
					setTeamBuildLogoURL(url);
				}
				setHasRetreivedLogo(true);
			} catch (error) {
				console.log(error);
			}
		}

		async function initializeCompanyLogo() {
			try {
				const url = await SettingService.getCompanyLogo(gameId);
				if (url) {
					setCompanyLogoURL(url);
				}
				setHasRetreivedLogo(true);
			} catch (error) {
				console.log(error);
			}
		}

		async function initializeNumMaxTries() {
			const fetchedNumMaxTries = await getNumberOfMaxTries(gameId);
			if (fetchedNumMaxTries) {
				setNumMaxTries(fetchedNumMaxTries);
			} else {
				await createNumberOfMaxTries(gameId);
				setNumMaxTries(0);
			}
		}

		async function initializeBackgroundColour() {
			const backgroundColourGameState = await getBackgroundColourGameState(gameId);
			if (backgroundColourGameState) {
				setBackgroundColour(backgroundColourGameState);
			}
		}

		async function initializeLogoColour() {
			const logoColourGameState = await getLogoColourGameState(gameId);
			if (logoColourGameState) {
				setLogoColour(logoColourGameState);
			}
		}

		initializeNumberOfFacts();
		initializeTeamBuildLogo();
		initializeCompanyLogo();
		initializeIsScoreVisible();
		initializeIsGuessingOnlyPremadeFacts();
		initializeNumMaxTries();
		initializeLogoColour();
		initializeBackgroundColour();
	}, [gameId]);

	return (
		<>
			<Navbar gameId={gameId} isAdmin={true} />
			<section className="home">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						margingBottom: "200px",
					}}
				>
					<h1>{translate("settings-title")}</h1>
					<span> {translate("settings-premade-facts")} </span>
					<Switch
						aria-label="Switch for Premade Fact Only Usage"
						onChange={(e) => handleChangeGuessingPremadeOnly(e)}
						checked={isGuessingPremadeOnly}
						sx={{
							marginBottom: "20px",
						}}
					/>
					<span> {translate("settings-number-facts-label")} </span>
					<FormControl
						variant="filled"
						sx={{ m: 3, minWidth: 120, backgroundColor: "#ffffff" }}
					>
						<TextField
							label={translate("settings-enter-number-facts")}
							variant="filled"
							type="number"	
							value={numberOfFacts}
							onChange={handleNumberOfFacts}
						/>
					</FormControl>
					{
						isUpdatingNumberOfFacts &&
						<Button
							disabled={!numberOfFacts}
							variant="contained"
							style={{
								marginBottom: "16px"
							}}
							onClick={updateNumberOfFacts}
						> 
							{translate("settings-save")}
						</Button>
					}
					<span> {translate("settings-score")} </span>
					<Switch
						aria-label="Switch for Score Visibility"
						onChange={(e) => handleChangeVisibleScoreSwitch(e)}
						checked={isScoreVisible}
						sx={{
							marginBottom: "20px",
						}}
					/>
					<span> {translate("settings-teambuildlogo")} </span>
					{hasRetreivedLogo && (
						<img src={teamBuildLogoURL} height="32" alt="Logo" />
					)}
					<label>
						<input
							hidden
							accept="image/*"
							id="icon-button-file"
							type="file"
							onChange={handleTeamBuildingLogoUpdate}
						/>
						<IconButton
							sx={{
								marginBottom: "20px",
								color: "white",
							}}
							aria-label="upload picture"
							component="span"
						>
							<FolderIcon />
						</IconButton>
					</label>

					<span color="white">{translate("settings-clientlogo")}</span>
					{hasRetreivedLogo && (
						<img src={companyLogoURL} height="32" alt="Logo" />
					)}
					<label>
						<input
							hidden
							accept="image/*"
							id="icon-button-file"
							type="file"
							onChange={handleCompanyLogoUpdate}
						/>
						<IconButton
							sx={{
								marginTop: "8px",
								color: "white",
							}}
							aria-label="upload picture"
							component="span"
						>
							<FolderIcon />
						</IconButton>
					</label>
					<br />
					<span> {translate("max-tries-label")} </span>
					<FormControl
						variant="filled"
						sx={{ m: 1, minWidth: 120, backgroundColor: "#ffffff" }}
					>
						<InputLabel id="max-tries-label">
							{translate("max-tries-label")}
						</InputLabel>
						<Select
							labelId="max-tries-select-label"
							id="max-tries-select"
							value={numMaxTries.toString()}
							label="Age"
							onChange={handleNumMaxTries}
						>
							<MenuItem value={0}>
								<em>{translate("max-tries-none")}</em>
							</MenuItem>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
						</Select>
					</FormControl>
					<br />
					<span className="setting-container">{translate("settings-background-colour")}</span>
					<input type="color" value={backgroundColour} onChange={handleBackgroundColour}/>
					{
						isUpdatingBackgroundColour && 
						<Button
							disabled={!backgroundColour}
							variant="contained"
							style={{marginTop: "12px"}}
							onClick={updateBackgroundColour}
						> 
							{translate("settings-save")}
						</Button>
					}
					<br />
					<span className="setting-container">{translate("settings-logo-colour")}</span>
					<input type="color" value={logoColour} onChange={handleLogoColour}/>
					{
						isUpdatingLogoColour && 
						<Button
							disabled={!logoColour}
							variant="contained"
							style={{marginTop: "12px"}}
							onClick={updateLogoColour}
						>
							{translate("settings-save")}
						</Button>
					}
					<Button
						sx={{ marginTop: "24px", marginBottom: "200px" }}
						variant="contained"
						onClick={handleBackClick}
					>
						{translate("back-button")}
					</Button>
				</Box>
			</section>
			<Footer
				gameId={gameId}
				cardProgress={null}
				isScoreVisible={null}
				playerId={null}
				isScore={false}
			/>
		</>
	);
};
