import { FirebaseError } from "firebase/app";
import {
	browserSessionPersistence,
	getAuth,
	setPersistence,
	signInWithEmailAndPassword,
} from "firebase/auth";

export async function firebaseLogin(email: string, password: string) {
	const auth = getAuth();
	try {
		await setPersistence(auth, browserSessionPersistence);
		await signInWithEmailAndPassword(auth, email, password);
	} catch (error) {
		if (error instanceof FirebaseError) {
			const errorCode = error.code;
			const formatedErrorMessage = getFormattedAuthErrorMessage(errorCode);
			throw new Error(formatedErrorMessage);
		}
	}
}

function getFormattedAuthErrorMessage(errorCode: string): string {
	let errorMessageArray: string[] = errorCode.substring(5).split("-");
	errorMessageArray = errorMessageArray.map(
		(x) => x[0].charAt(0).toUpperCase() + x.substr(1).toLowerCase()
	);
	return errorMessageArray.reduce(
		(accumulator, currentValue) => accumulator + " " + currentValue
	);
}
