import React, { useContext } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { firebaseLogin } from "../services/AuthenticationService";
import UTurnLogo from "../assets/UTURN1.png";
import { LangContext } from "../context/lang";

export const LoginPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassowrd] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [searchParams] = useSearchParams();
	const redirectUrl = searchParams.get("redirect")!;

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	async function handleLogin() {
		try {
			await firebaseLogin(email, password);
		} catch (error) {
			console.log(error);
			if (error instanceof Error) {
				setErrorMessage(error.message);
			}
		}
	}

	function isLoginInfoComplete() {
		return password.length <= 0 || email.length <= 0;
	}

	function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
		const emailText = e.target.value;
		setEmail(emailText);
	}

	function handlePasswordInput(e: React.ChangeEvent<HTMLInputElement>) {
		const passwordText = e.target.value;
		setPassowrd(passwordText);
	}

	useEffect(() => {
		async function setMonitorAuthState() {
			const auth = getAuth();
			onAuthStateChanged(auth, async (user) => {
				if (user) {
					if (redirectUrl) {
						navigate(redirectUrl);
					} else {
						navigate("/");
					}
				}
			});
		}
		setMonitorAuthState();
	}, [navigate, redirectUrl]);

	return (
		<>
			<section className="home">
				<Container
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<Paper
						variant="outlined"
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							paddingTop: "20px",
							paddingLeft: "20px",
							paddingRight: "20px",
							paddingBottom: "30px",
							minHeight: "400px",
							minWidth: "300px",
							justifyContent: "space-evenly",
						}}
					>
						<img src={UTurnLogo} height="150px" alt="Logo" />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<TextField
								sx={{ m: 1, width: "24ch" }}
								label={translate("login-email")}
								variant="outlined"
								onChange={handleEmailInput}
							/>
							<FormControl sx={{ m: 1, width: "24ch" }} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-password">
									{translate("login-password")}
								</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									type={showPassword ? "text" : "password"}
									onChange={handlePasswordInput}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
									label={translate("login-password")}
								/>
							</FormControl>
						</Box>
						<Typography variant="body1" sx={{ color: red[500] }}>
							{" "}
							{errorMessage}{" "}
						</Typography>
						<Button
							disabled={isLoginInfoComplete()}
							sx={{ m: 2 }}
							variant="contained"
							size="large"
							onClick={handleLogin}
						>
							{translate("login-button")}
						</Button>
					</Paper>
				</Container>
			</section>
		</>
	);
};
