import { httpsCallable } from "firebase/functions";
import { functions } from "..";

export async function logInfo(message: string) {
	const addInfo = httpsCallable(functions, 'addInfo');
	await addInfo(message)
}

export async function logError(message: string) {
	const addError = httpsCallable(functions, 'addError');
	await addError(message)
}