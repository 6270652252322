import React from "react";
import { Box, Card, IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import saveAs from "file-saver";
import JSZip from "jszip";
import { getAllScores } from "../services/ScoreService";
import { getAllFactsForExports } from "../services/FactService";

interface DataExporterProps {
  gameId: string;
  headerText: string
  pictureText: string;
  factsText: string;
}

export const DataExporter = (props: DataExporterProps) => {
  async function exportPictures() {
    const zip = new JSZip();
    try {
      const scoreDetails = await getAllScores(props.gameId);
      if (scoreDetails) {
        for (const scoreDetail of scoreDetails) {
          if (scoreDetail.playerPicture !== "/broken-image.jpg") {
            const name = scoreDetail.playerName;
            const playerIdShort = scoreDetail.playerId.substring(0,5)
            const file = await fetch(scoreDetail.playerPicture)
            const blob = await file.blob();
            zip.file(`images/${name} ${playerIdShort}.png`, blob);
          }
        }
        zip.generateAsync({type:"blob"})
        .then(function(content) {
            saveAs(content, "example.zip");
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function exportFacts() {
    const factCSV = []
    try {
      const factDetails = await getAllFactsForExports(props.gameId);
      if (factDetails) {
        factCSV.push("Player IDs,Player Names,Facts")
        for (const factDetail of factDetails) {
          const playerId = factDetail.playerId
          const playerName = factDetail.playerName
          const fact = factDetail.fact
          factCSV.push(`${playerId},${playerName},${fact}`)
        }
      }

      const blob = new Blob([factCSV.join('\n')], { type: 'text/csv' })
      const a = document.createElement('a')
      a.download = "facts.csv"
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    } catch (error) {
      console.log(error)
    }
  }
    
	return (
		<Card
			sx={{
        padding: "20px",
				display: "flex",
        flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
      <h2>{props.headerText}</h2>
      <Box
        sx={{
          marginTop: "10px",
          width: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
				<h3>{props.pictureText}</h3>
        <IconButton aria-label="download pictures" size="large" onClick={exportPictures}>
          <DownloadIcon />
        </IconButton>
			</Box>
      <Box
        sx={{
          width: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
				<h3>{props.factsText}</h3>
        <IconButton aria-label="download facts" size="large" onClick={exportFacts}>
          <DownloadIcon />
        </IconButton>
			</Box>
		</Card>
	);
};
