import React, { useContext } from "react";
import { Box, Button, Tab, Tabs, Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
	getGameStartState,
	updateGameStartState,
} from "../services/GameStatesService";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { PlayerLinkQR } from "../components/qr-codes/PlayerLinkQR";
import { Leaderboard } from "../components/Leaderboard";
import { LostPageQR } from "../components/qr-codes/LostPageQR";
import { useEffect, useState } from "react";
import { ConfirmationDialog } from "../components/dialogs/ConfirmationDialog";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { DataExporter } from "../components/DataExporter";
import { LangContext } from "../context/lang";

export const HostToolsPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const navigate = useNavigate();
	const params = useParams();
	const gameId: string = params.gameId!;
	const [tabValue, setTabValue] = useState(0);
	const [isGameStarted, setIsGameStarted] = useState(false);
	const [isEndGameDialogShowing, setIsEndGameDialogShowing] = useState(false);

	const handleTabChange = (
		event: React.SyntheticEvent,
		newTabValue: number
	) => {
		setTabValue(newTabValue);
	};

	const redirectLeaderboardPage = () => {
		navigate(`/${gameId}/leaderboard`);
	};

	function startGame() {
		updateGameStartState(gameId, true);
		setIsGameStarted(true);
	}

	function endGame() {
		updateGameStartState(gameId, false);
		redirectLeaderboardPage();
	}

	const handleEndGameDialogOpen = async () => {
		setIsEndGameDialogShowing(true);
	};

	const handleGameResetDialogClose = () => {
		setIsEndGameDialogShowing(false);
	};

	function handleEndGame() {
		endGame();
	}

	useEffect(() => {
		async function fetchGameState() {
			const hasGameStarted = await getGameStartState(gameId);
			setIsGameStarted(hasGameStarted);

			const auth = getAuth();
			onAuthStateChanged(auth, (user) => {
				if (!user) {
					navigate(`/login?redirect=/${gameId}/adminboard`);
				}
			});
		}
		fetchGameState();
	}, [gameId]);

	return (
		<>
			<section className="home">
				<Navbar gameId={gameId} isAdmin={true}></Navbar>
				<Box
					className="overflow-protection"
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<Card
						sx={{
							margin: "40px",
							minWidth: "680px"
						}}
					>
							<Tabs
								variant="fullWidth"
								value={tabValue}
								onChange={handleTabChange}
								centered
								aria-label="QR-tabs"
							>
								<Tab label={translate("player-link-tab-header")} />
								<Tab label={translate("lost-page-tab-header")} />
							</Tabs>
						<Box
							sx={{
								padding: "30px",
								color: "#000000"
							}}
						>
							{tabValue === 0 && (
								<Box>
									<PlayerLinkQR
										gameId={gameId}
										qrHeaderText={translate("player-links-qr")}
										qrInstructions={translate("qr-enlarge-instructions")}
									/>
								</Box>
							)}
							{tabValue === 1 && (
								<Box>
									{
										isGameStarted ?
										<LostPageQR
											gameId={gameId}
											qrHeaderText={translate("lost-page-qr-header")}
											qrInstructions={translate("qr-enlarge-instructions")}
										/> :
										<h3>{translate("lost-page-qr-unavailable")}</h3>
									}
								</Box>
							)}
						</Box>
						<Box
							sx={{ marginBottom: "60px" }}
						>
							<div>
								<Button
									disabled={isGameStarted}
									sx={{ marginBottom: "10px" }}
									variant="contained"
									onClick={() => startGame()}
								>
									{translate("player-links-start")}
								</Button>
							</div>
							<div>
								<Button
									disabled={!isGameStarted}
									variant="contained"
									onClick={() => handleEndGameDialogOpen()}
								>
									{translate("player-links-end")}
								</Button>
							</div>
						</Box>
					</Card>
					<Leaderboard
						gameId={gameId}
						isRealtime={true}
					/>
					<DataExporter
						gameId={gameId}
						headerText={translate("data-exporter-header")}
						factsText={translate("data-exporter-fact-text")}
						pictureText={translate("data-exporter-picture-text")}
					/>
				</Box>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</section>
			<ConfirmationDialog
				titleText={translate("endgame-dialog-title")}
				bodyText={translate("endgame-dialog-body-text")}
				confirmationText={translate("endgame-dialog-confirmation-text")}
				confirmButtonText={translate("endgame-dialog-confirmation-text")}
				cancelButtonText={translate("game-dialog-cancel")}
				open={isEndGameDialogShowing}
				onClose={handleGameResetDialogClose}
				onConfirm={handleEndGame}
			/>
		</>
	);
};
