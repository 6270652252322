import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "..";

class SettingService {
	async getTeamBuildingLogo(gameId: string) {
		try {
			let logoURL = "";
			const settings = doc(db, "Games", gameId, "Settings", "TeamBuildingLogo");
			const teamBuildingLogoSnapshot = await getDoc(settings);

			if (teamBuildingLogoSnapshot.exists()) {
				console.log("Document data:", teamBuildingLogoSnapshot.data());
				logoURL = teamBuildingLogoSnapshot!.data()!.logo;
			}
			return logoURL;
		} catch (error) {
			console.log(error);
		}
	}

	async setTeamBuildingLogo(gameId: string, url: string) {
		try {
			const settings = doc(db, "Games", gameId, "Settings", "TeamBuildingLogo");
			await setDoc(settings, {
				logo: url,
			});
			console.log("Succesfully Completed Upload");
		} catch (error) {
			console.log(error);
		}
	}

	async getCompanyLogo(gameId: string) {
		try {
			let logoURL = "";
			const settings = doc(db, "Games", gameId, "Settings", "CompanyLogo");
			const companyLogoSnapshot = await getDoc(settings);

			if (companyLogoSnapshot.exists()) {
				console.log("Document data:", companyLogoSnapshot.data());
				logoURL = companyLogoSnapshot!.data()!.logo;
			}
			return logoURL;
		} catch (error) {
			console.log(error);
		}
	}

	async setCompanyLogo(gameId: string, url: string) {
		try {
			const settings = doc(db, "Games", gameId, "Settings", "CompanyLogo");
			await setDoc(settings, {
				logo: url,
			});
			console.log("Succesfully Completed Upload");
		} catch (error) {
			console.log(error);
		}
	}
}

export default new SettingService();
