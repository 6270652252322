import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Button
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { PlayerProfileCreation } from "../PlayerProfileCreation"
import { LangContext } from "../../context/lang";
import { PlayerFactSummary } from "../../services/FactGenerationService";

interface FactGenerateDialogProps {
  gameId: string;
	open: boolean;
	onClose: () => void;
	onConfirm: (playerFact: PlayerFactSummary) => void;
}

export const FactGenerateDialog = (props: FactGenerateDialogProps) => {
  const regex = /^[0-9\b]+$/;

  const {
		dispatch: { translate },
	} = useContext(LangContext);

  const [activeStep, setActiveStep] = useState(0);
  const [numberOfFacts, setNumberOfFacts] = useState('');
  const stepLabels = [
    translate("fact-generator-set-number-of-facts"),
    translate("fact-generator-submit-profile")
  ]

  const handleFactInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberOfFacts = e.target.value;

		if (numberOfFacts === "" || regex.test(numberOfFacts)) {
			setNumberOfFacts(numberOfFacts);
		}
	};

  const handleNumberOfFactSubmission = () => {
		setActiveStep(1)
	};

  const handleNumberOfFactReturn = () => {
		setActiveStep(0)
	};

  const handleStoreAndClose = (playerFactSummary: PlayerFactSummary) => {
    props.onConfirm(playerFactSummary)
    setNumberOfFacts(numberOfFacts);
    setActiveStep(0);
  }
  
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={"md"}
      scroll="paper"
    >
      <DialogTitle>
        {translate("create-profile-title")}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{stepLabels[0]}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{stepLabels[1]}</StepLabel>
          </Step>
        </Stepper>
        {
          activeStep === 0 && 
            <div className="padded-container">
              <TextField
                label={translate("fact-generator-number-of-facts")}
                variant="filled"
                type="number"	
                value={numberOfFacts}
                onChange={handleFactInputChange}
              />
              <Button 
                sx={{marginTop: "8px"}}
                variant="contained"
                onClick={handleNumberOfFactSubmission}
              >
                {translate("fact-generator-submit")}
              </Button>
            </div>
        }
        {
          activeStep === 1 && 
            <div className="padded-container">
              <PlayerProfileCreation 
                gameId={props.gameId}
                numberOfFacts={Number(numberOfFacts)}
                isPremadeProfile={true}
                handleStoreAndClose={handleStoreAndClose}
              />
              <Button 
                sx={{marginTop: "8px"}}
                variant="contained"
                onClick={handleNumberOfFactReturn}
              >
                {translate("fact-generator-back")}
              </Button>
            </div>
        }
      </DialogContent>
    </Dialog>
  )
}
