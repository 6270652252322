import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import actress from "../default-avatar-photos/actress.png";
import female from "../default-avatar-photos/female.png";
import male from "../default-avatar-photos/male.png";
import bolivianGirl from "../default-avatar-photos/bolivian-girl.png";
import caveman from "../default-avatar-photos/caveman.png";
import otherFemale from "../default-avatar-photos/other-female.png";
import manager from "../default-avatar-photos/manager.png";
import supportPerson from "../default-avatar-photos/support-person.png";
import userMale from "../default-avatar-photos/user-male.png";
import writeMale from "../default-avatar-photos/writer-male.png";
import React, { useState } from "react";

interface DefaultAvatarButtonProps {
	isPremadeProfile: boolean;
	onImageSet: (imagePreview: string) => void;
}

export const DefaultAvatarButton = (props: DefaultAvatarButtonProps) => {
	const ITEM_HEIGHT = 50;
	const defaultAvatars = [
		actress,
		female,
		male,
		bolivianGirl,
		caveman,
		otherFemale,
		manager,
		supportPerson,
		userMale,
		writeMale,
	];

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleSelectAvatar(avatar: string) {
		props.onImageSet(avatar);
		handleClose();
	}

	const iconColour = () => (props.isPremadeProfile ? 'grey' : 'white')

	// Dropdown States
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	return (
		<>
			<Tooltip title="Default Avatars">
				<IconButton
					onClick={handleClick}
					aria-controls={open ? "avatars-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					<FaceIcon sx={{ color: iconColour(), fontSize: 27 }} />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				MenuListProps={{ "aria-labelledby": "avatar-list" }}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{defaultAvatars.map((avatar) => (
					<MenuItem key={avatar} onClick={() => handleSelectAvatar(avatar)}>
						<Avatar src={avatar} />
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
