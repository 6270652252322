import { 
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  deleteDoc,
  where,
  updateDoc,
  addDoc
} from "firebase/firestore";
import { db } from "..";
import { FactModel, FactModelGeneratorDTO, getAllFactsForPlayer } from "./FactService";
import { logInfo, logError } from "./LoggingService";

export interface PlayerFactSummary {
	playerId: string;
  playerName: string;
  isPremade: boolean;
  playerPicture: string;
	playerFacts: Array<FactModelGeneratorDTO>;
}

export async function initializePlayerFacts(gameId: string) {
	try {
		const profiles = collection(db, "Games", gameId, "Profiles");

    const queryOfProfiles = query(profiles, orderBy("name"));
    const querySnapshot = await getDocs(queryOfProfiles);
  
		const playerProfiles: Array<PlayerFactSummary> = [];
		for (const document of querySnapshot.docs) {
			if (document.data()) {
        const facts = await getAllFactsForPlayer(gameId, document.id)
				const playerProfile: PlayerFactSummary = {
					playerId: document.id,
					playerName: document.data().name,
          playerPicture: document.data().picture,
          playerFacts: facts,
          isPremade: document.data().isPremade
				};
				playerProfiles.push(playerProfile);
			}
		}
		logInfo(`Successfully got all the Player Facts: ${playerProfiles.length}`)
		return { playerProfiles };
	} catch (error) {
		logError(`Firebase was not able to get all player facts`)
		throw new Error(`Firebase was not able to get all player facts`);
	}
}

export async function updatePlayerName(gameId: string, id: string, name: string) {
	try {
		const profile = doc(db, "Games", gameId, "Profiles", id);
		await updateDoc(profile, {
			name: name,
		});
		logInfo(`Successfully Updated profile name to ${name}`);
	} catch (error) {
		logError(`Firestore could not update profile name to ${name} - ${error}`)
		throw new Error(`Firestore could not update profile name to ${name} - ${error}`);
	}
}

export async function deletePlayerProfile(gameId: string, profileId: string) {
	try {
		const profile = doc(db, "Games", gameId, "Profiles", profileId);
		await deleteDoc(profile);
		logInfo(
			`Firebase Succesfully Deleted the Player Profile for gameId: ${gameId} and playerId: ${profileId}`
		);
	} catch (error) {
		logError(`Firebase Was Not Able to Delete the Player Profile for gameId: ${gameId} and playerId: ${profileId} - ${error}`)
		throw new Error(`Firebase Was Not Able to Delete the Player Profile for gameId: ${gameId} and playerId: ${profileId} - ${error}`);
	}
}

export async function deletePlayerFacts(gameId: string, profileId: string) {
	try {
    const factsRef = collection(db, "Games", gameId, "Facts");
    const q = query(factsRef, where("playerId", "==", profileId));
    const querySnapshot = await getDocs(q);
    for (const document of querySnapshot.docs) {
			deleteDoc(document.ref);
		}
		logInfo(
			`Firebase Succesfully Deleted the facts for game: ${gameId} and playerId: ${profileId}`
		);
	} catch (error) {
		logError(`Firebase Was Not Able to Delete the facts for game: ${gameId} and playerId: ${profileId} - ${error}`)
		throw new Error(`Firebase Was Not Able to Delete the facts for game: ${gameId} and playerId: ${profileId} - ${error}`);
	}
}

export async function addFact(gameId: string, factItem: FactModel) {
	try {
    const playerFactsRef = collection(db, "Games", gameId, "Facts");
		const docRef = await addDoc(playerFactsRef, factItem);
		logInfo(`Successfully Updated profile fact to ${factItem}`);
    return docRef.id
	} catch (error) {
		logError(`Firestore could not update profile fact to ${factItem} - ${error}`)
		throw new Error(`Firestore could not update profile fact to ${factItem} - ${error}`);
	}
}

export async function updateFact(gameId: string, id: string, fact: string) {
	try {
		const profile = doc(db, "Games", gameId, "Facts", id);
		await updateDoc(profile, {
			fact: fact,
		});
		logInfo(`Successfully Updated profile fact to ${fact}`);
	} catch (error) {
		logError(`Firestore could not update profile fact to ${fact} - ${error}`)
		throw new Error(`Firestore could not update profile fact to ${fact} - ${error}`);
	}
}

export async function deletePlayerFact(gameId: string, factId: string) {
	try {
    const factsRef = doc(db, "Games", gameId, "Facts", factId);
    deleteDoc(factsRef);
		logInfo(
			`Firebase Succesfully Deleted the facts for game: ${gameId} and factId: ${factId}`
		);
	} catch (error) {
		logError(`Firebase Was Not Able to Delete the facts for game: ${gameId} and factId: ${factId} - ${error}`)
		throw new Error(`Firebase Was Not Able to Delete the facts for game: ${gameId} and factId: ${factId} - ${error}`);
	}
}