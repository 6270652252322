import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "..";
import { prettyPrintCreateDTO } from "../utils/PrettyPrintService";
import { logError, logInfo } from "./LoggingService";

export interface PlayerProfileModel {
	name: string;
	picture: string;
	url: string;
}

export interface PlayerCreateDTO {
	name: string;
	picture: string;
	isPremadeProfile: boolean;
}

export interface PlayerGetDTO {
	name: string;
	picture: string;
}

export async function createPlayerProfile(
	gameId: string,
	playerDetails: PlayerCreateDTO
) {
	try {
		const profiles = collection(db, "Games", gameId, "Profiles");
		const snapshot = await addDoc(profiles, {
			picture: playerDetails.picture,
			name: playerDetails.name,
			url: "",
			isPremade: playerDetails.isPremadeProfile
		});
		logInfo(
			`Successfully Created profile with player details: ${prettyPrintCreateDTO(playerDetails)}`
		);
		return snapshot.id;
	} catch (error) {
		logError(`Firestore could not create profile with player details: ${prettyPrintCreateDTO(playerDetails)} - ${error}`)
		throw new Error(`Firestore could not create profile with player details: ${prettyPrintCreateDTO(playerDetails)} - ${error}`)
	}
}

export async function updatePlayerURLToId(gameId: string, id: string) {
	try {
		const profile = doc(db, "Games", gameId, "Profiles", id);
		await updateDoc(profile, {
			url: id,
		});
		logInfo(`Successfully Updated profile url to ${id}`);
	} catch (error) {
		logError(`Firestore could not update profile url to ${id} - ${error}`)
		throw new Error(`Firestore could not update profile url to ${id} - ${error}`);
	}
}

export async function getAllButCurrentPlayer(gameId: string, playerId: string) {
	try {
		const profiles = collection(db, "Games", gameId, "Profiles");
		const queryOfProfiles = query(profiles, where("url", "!=", playerId));
		const querySnapshot = await getDocs(queryOfProfiles);
		const playerProfiles: Array<PlayerGetDTO> = [];
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerProfile: PlayerGetDTO = {
					name: document.data().name,
					picture: document.data().picture,
				};
				playerProfiles.push(playerProfile);
			}
		}
		logInfo(`Successfully got all the Player Details except for playerId: ${playerId}`)
		return playerProfiles;
	} catch (error) {
		logError(`Firebase was not able to get all players other than itself. Current player: ${playerId} - ${error}`)
		throw new Error(`Firebase was not able to get all players other than itself. Current player: ${playerId} - ${error}`);
	}
}

export async function getAllPremadeProfiles(gameId: string) {
	try {
		const profiles = collection(db, "Games", gameId, "Profiles");
		const queryOfProfiles = query(profiles, where("isPremade", "==", true));
		const querySnapshot = await getDocs(queryOfProfiles);
		const playerProfiles: Array<PlayerGetDTO> = [];
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerProfile: PlayerGetDTO = {
					name: document.data().name,
					picture: document.data().picture,
				};
				playerProfiles.push(playerProfile);
			}
		}
		logInfo(`Successfully got all premade profiles`)
		return playerProfiles;
	} catch (error) {
		logError(`Firebase was not able to get all premade profiles - ${error}`)
		throw new Error(`Firebase was not able to get all premade profile - ${error}`);
	}
}

export async function deleteAllPlayerProfilesInGame(gameId: string) {
	try {
		const profiles = collection(db, "Games", gameId, "Profiles");
		const queryOfProfiles = query(profiles, where("isPremade", "==", false));
		const querySnapshot = await getDocs(queryOfProfiles);
		for (const document of querySnapshot.docs) {
			deleteDoc(document.ref);
		}
		logInfo(
			`Firebase Succesfully Deleted All the Player Profiles for gameId: ${gameId}`
		);
	} catch (error) {
		logError(`Firebase Was Not Able to Delete All the Player Profiles for gameId: ${gameId} - ${error}`)
		throw new Error(`Firebase Was Not Able to Delete All the Player Profiles for gameId: ${gameId} - ${error}`);
	}
}
