import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, useState } from "react";
import { LangContext } from "../context/lang";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ScoreLegend } from "./ScoreLegend";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { PlayerScore } from "./PlayerScore";
import { CardSpotProgress } from "./UTurnCard";

interface Props {
	window?: () => Window;
	gameId: string;
	isScore: boolean;
	cardProgress: CardSpotProgress[][] | null;
	isScoreVisible: boolean | null;
	playerId: string | null;
}

const Footer = (props: Props) => {
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const {
		dispatch: { setLanguage },
	} = useContext(LangContext);

	function handleSelectLanguage(
		language: string
	): React.MouseEventHandler<HTMLLIElement> | undefined {
		handleCloseUserMenu();
		setLanguage(language);
		return undefined;
	}

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar sx={{ top: "auto", bottom: 0 }}>
			<Toolbar>
				<Box sx={{ flexGrow: 0 }}>
					<Button color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						EN/FR
					</Button>
					<Menu
						sx={{ mt: "0px" }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						<MenuItem key={"EN"} onClick={() => handleSelectLanguage("EN")}>
							<Typography textAlign="center">EN</Typography>
						</MenuItem>
						<MenuItem key="FR" onClick={() => handleSelectLanguage("FR")}>
							<Typography textAlign="center">FR</Typography>
						</MenuItem>
						{/* //   ))} */}
					</Menu>
				</Box>
				{props.isScore ? (
					<>
						<PlayerScore
							gameId={props.gameId}
							cardProgress={props.cardProgress!}
							playerId={props.playerId!}
							isScoreVisible={props.isScoreVisible!}
						/>
						<Box sx={{ flexGrow: 0 }}>
							<Button
								color="inherit"
								onClick={handleOpenNavMenu}
								sx={{ alignItems: "right", p: 0 }}
							>
								<WorkspacePremiumIcon />
							</Button>
							<Menu
								sx={{ mt: "0px" }}
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
							>
								<ScoreLegend isScoreVisible={true} />
							</Menu>
						</Box>
					</>
				) : null}
			</Toolbar>
		</AppBar>
	);
};

export default Footer;
