import { doc, getDoc } from "firebase/firestore";
import { db } from "..";

export async function getUserRole(userId: string) {
	try {
		let role = "";
		const users = doc(db, "Users", userId);
		const userSnapshot = await getDoc(users);

		if (userSnapshot.exists()) {
			console.log("Document data:", userSnapshot.data());
			role = userSnapshot!.data()!.role;
		}
		return role;
	} catch (error) {
		console.log(error);
	}
}
