import { FactModel, FactModelCreateDTO } from "../services/FactService";
import { PlayerCreateDTO } from "../services/PlayerProfileService";
import { ScoreCreateDTO } from "../services/ScoreService";

export function prettyPrintCreateDTO(playerDetails: PlayerCreateDTO): string {
	return `{name: ${playerDetails.name}, picture: ${playerDetails.picture} isPremadeProfile: ${playerDetails.isPremadeProfile}}`;
}

export function prettyPrintFactDetailsArray(factDetails: FactModelCreateDTO) {
	let printDetails = `Fact Details:{ playerName: ${factDetails.playerName}[`;
	for (const fact of factDetails.facts) {
		printDetails = printDetails + `${fact}`;
	}
	printDetails = printDetails + "]";
	return printDetails;
}

export function prettyPrintFactDetails(factDetails: FactModel) {
	const printDetails = `{ playerId: ${factDetails.playerId}, playerName: ${factDetails.playerName}, fact: ${factDetails.fact} }`
	return printDetails;
}

export function prettyPrintScoreDetailsDTO(scoreDetails: ScoreCreateDTO) {
	const printDetails = `Score Details:{ playerName: ${scoreDetails.playerName},
        playerId: ${scoreDetails.playerId}, 
        playerPicture: ${scoreDetails.playerPicture}}`;
	return printDetails;
}
