import { doc, setDoc } from "firebase/firestore";
import { db } from "..";

export async function createGame(gameId: string) {
	try {
		const gameDocRef = doc(db, "Games", gameId);
		await setDoc(gameDocRef, {});
		console.log(`Succesfully Create Game with ID: ${gameId}`);
	} catch (error) {
		console.log(
			`Firestore could not Create Game with ID: ${gameId}\n ${error}`
		);
	}
}
