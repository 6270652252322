import React, { useContext } from "react";
import { Box } from "@mui/material";
import { LangContext } from "../context/lang";

interface ScoreLegendProps {
	isScoreVisible: boolean;
}

export const ScoreLegend = (props: ScoreLegendProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	return (
		<>
			{props.isScoreVisible === true ? (
				<Box
					sx={{
						margin: "12px",
						height: "150px",
						color: "black",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						justifyContent: "space-evenly",
					}}
				>
					<span>{translate("uturn-legend-space")}</span>
					<span>{translate("uturn-legend-row")}</span>
					<span>{translate("uturn-legend-column")}</span>
					<span>{translate("uturn-legend-diagonal")}</span>
					<span>{translate("uturn-legend-entire-card")}</span>
				</Box>
			) : null}
		</>
	);
};
