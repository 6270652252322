import React from "react";
import { StartPage } from "./pages/StartPage";
import { NumberOfPlayersPage } from "./pages/NumberOfPlayersPage";
import { PlayerProfile } from "./pages/PlayerProfile";
import { UturnPage } from "./pages/UturnPage";
import { SettingsPage } from "./pages/SettingsPage";
import { LeaderboardPage } from "./pages/LeaderboardPage";
import { Routes, Route } from "react-router-dom";
import { HostToolsPage } from "./pages/HostToolsPage";
import { GameGeneratorPage } from "./pages/GameGeneratorPage";
import { LoginPage } from "./pages/LoginPage";
import { FactGeneratorPage } from "./pages/FactGenerator";
import { WaitingPage } from "./pages/WaitingPage";

export const Router = () => {
	return (
		<Routes>
			<Route path="/" element={<GameGeneratorPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/:gameId" element={<StartPage />} />
			<Route
				path="/:gameId/player-profile"
				element={<PlayerProfile />}
			/>
			<Route
				path="/:gameId/player-profile/override"
				element={<PlayerProfile />}
			/>
			<Route
				path="/:gameId/number-players"
				element={<NumberOfPlayersPage />}
			/>
			<Route
				path="/:gameId/settings"
				element={<SettingsPage />}
			/>
			<Route
				path="/:gameId/uturn-page/:playerURL"
				element={<UturnPage />}
			/>
			<Route
				path="/:gameId/uturn-page/"
				element={<UturnPage />}
			/>
			<Route
				path="/:gameId/leaderboard"
				element={<LeaderboardPage />}
			/>
			<Route
				path="/:gameId/adminboard"
				element={<HostToolsPage />}
			/>
			<Route
				path="/:gameId/player-facts"
				element={<FactGeneratorPage />}
			/>
			<Route
				path="/:gameId/waiting-room"
				element={<WaitingPage />}
			/>
		</Routes>
	);
};
