import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmationDialogProps {
	titleText: string;
	bodyText: string;
	confirmationText: string;
	cancelButtonText: string;
	confirmButtonText: string;
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
	const [confirmationText, setConfirmationText] = useState("");

	function handleTextInput(
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) {
		const currentText = e.target.value;
		setConfirmationText(currentText);
	}

	function handleConfirmation() {
		if (confirmationText === props.confirmationText) {
			props.onConfirm();
			setConfirmationText('')
		}
	}

	return (
		<>
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>{props.titleText}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{props.bodyText} <strong>{props.confirmationText}</strong>
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						label={props.confirmationText}
						type="text"
						fullWidth
						variant="outlined"
						onChange={(e) => handleTextInput(e)}
						value={confirmationText}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.onClose}>{props.cancelButtonText}</Button>
					<Button onClick={handleConfirmation}>
						{props.confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
