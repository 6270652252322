import React, { useContext } from "react";
import UTurnLogoWhite from "../assets/UTURN2.png";
import { Box, CircularProgress } from "@mui/material";
import { LangContext } from "../context/lang";

interface LoadingViewProps {
	isWaitingForHost: boolean;
	showLoadingIcon: boolean;
}

export const LoadingView = (props: LoadingViewProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	window.onpopstate = function () {
		window.history.go(1);
	};

	window.addEventListener("beforeunload", (ev) => {
		ev.preventDefault();
		return (ev.returnValue = "Are you sure you want to close?");
	});

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100%",
			}}
		>
			<Box>
				{props.isWaitingForHost && <h1>{translate("loading-message")}</h1>}
				<img src={UTurnLogoWhite} alt="Uturn Logo" height="180" />
				{props.showLoadingIcon &&
					<Box sx={{ marginTop: 1 }}>
						<CircularProgress />
					</Box>
				}
			</Box>
		</Box>
	);
};
