import React, { useContext, useEffect } from "react";
import { Box, Button, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { getGuessingPremadeFactsOnly, updateGuessingPremadeFactsOnly, updateNumberOfFactsState } from "../services/GameStatesService";
import { LangContext } from "../context/lang";

export const NumberOfPlayersPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const [numberOfPlayers, setNumberOfPlayers] = useState("");
	const [isGuessingPremadeOnly, setIsGuessingPremadeOnly] = useState(false);
	const [errorMessage, setErrorMessage] = useState("")
	const regex = /^[0-9\b]+$/;

	const navigate = useNavigate();
	const params = useParams();
	const gameId: string = params.gameId!;

	const redirectToAdminboardPage = (factsPerPerson: number) => {
		navigate(`/${gameId}/adminboard`, {
			state: { numberOfFacts: factsPerPerson },
		});
	};

	const preventRefresh = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
		}
	};

	const handleChangeGuessingPremadeOnly = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		updateGuessingPremadeFactsOnly(gameId, event.target.checked);
		setIsGuessingPremadeOnly(event.target.checked);
	};

	function handleSubmit() {
		let factsPerPerson = 1;
		const playerCount = parseInt(numberOfPlayers);
		if (playerCount <= 1) {
			setErrorMessage("Cannot be less than 1");
		} else {
			while (factsPerPerson * (playerCount - 1) < 25) {
				factsPerPerson++;
			}
			updateNumberOfFactsState(gameId, factsPerPerson);
			redirectToAdminboardPage(factsPerPerson);
		}
	}

	const handlePlayerInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberPlayers = e.target.value;

		if (numberPlayers === "" || regex.test(numberPlayers)) {
			setNumberOfPlayers(numberPlayers);
		}
	};

	useEffect(() => {
		async function initializeIsGuessingOnlyPremadeFacts() {
			const fetchedIsGuessingOnlyPremadeFacts = await getGuessingPremadeFactsOnly(gameId);
			if (fetchedIsGuessingOnlyPremadeFacts) {
				setIsGuessingPremadeOnly(fetchedIsGuessingOnlyPremadeFacts);
			}
		}

		initializeIsGuessingOnlyPremadeFacts();
	}, [gameId]);

	return (
		<>
			<Navbar gameId={gameId} isAdmin={true}></Navbar>
			<section className="home">
				<h1>{translate("number-players-title")}</h1>
				<Box
					component="form"
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						"& .MuiTextField-root": {
							m: 1,
							width: "25ch",
							backgroundColor: "white",
						},
					}}
					noValidate
				>
					<Box sx={{display: "flex", alignItems: "center", margin: 2}}>
						<span> {translate("number-player-premade-facts")} </span>
						<Switch
							aria-label="Switch for playing with only premade facts"
							onChange={(e) => (handleChangeGuessingPremadeOnly(e))}
							checked={isGuessingPremadeOnly}
						/>
					</Box>
					{!isGuessingPremadeOnly && <TextField
						sx={{
							"& label.Mui-focused": {
								color: "black",
							},
						}}
						id="player-number"
						label={translate("number-players-entry-text")}
						variant="filled"
						type="number"	
						value={numberOfPlayers}
						onChange={handlePlayerInputChange}
						onKeyDown={(e) => preventRefresh(e)}
					/>}
					{errorMessage&&<small className='red'>{errorMessage}</small>}
					<Button
						sx={{ color: "white", marginTop: 3 }}
						variant="contained"
						disableElevation
						onClick={handleSubmit}
					>
						{translate("number-players-submit")}
					</Button>
				</Box>
			</section>
			<Footer
				gameId={gameId}
				cardProgress={null}
				isScoreVisible={null}
				playerId={null}
				isScore={false}
			/>
		</>
	);
};
