import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import SettingService from "../services/SettingService";
import UTurnLogoWhite from "../assets/UTURN2.png";
import NavigationDrawer from "./NavigationDrawer";

interface NavbarProps {
	gameId: string;
	isAdmin: boolean;
}
const Navbar = (props: NavbarProps) => {
	const [companyLogoURL, setCompanyLogoURL] = useState("");
	const [teamBuildLogoURL, setTeamBuildLogoURL] = useState("");

	useEffect(() => {
		async function initializeTeamBuildLogo() {
			try {
				const url = await SettingService.getTeamBuildingLogo(props.gameId);
				if (url) {
					setTeamBuildLogoURL(url);
				}
			} catch (error) {
				console.log(error);
			}
		}

		async function initializeCompanyLogo() {
			try {
				const url = await SettingService.getCompanyLogo(props.gameId);
				if (url) {
					setCompanyLogoURL(url);
				}
			} catch (error) {
				console.log(error);
			}
		}

		initializeTeamBuildLogo();
		initializeCompanyLogo();
	}, [props.gameId]);

	return (
		<AppBar position="static" sx={{ top: "auto", bottom: 0 }}>
			<Toolbar sx={{ p: 1 }}>
				{/* This is what is displayed at in mobile views */}
				<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }}}>
					{props.gameId && props.isAdmin && <NavigationDrawer gameId={props.gameId}/>}	
				</Box>
				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
					{teamBuildLogoURL && <img src={teamBuildLogoURL} height="52" alt="Team Building Logo" loading="lazy"/>}
				</Box>

				{/* This is what is displayed at in desktop views */}
				<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex'}}}>
					{props.gameId && props.isAdmin && <NavigationDrawer gameId={props.gameId}/>}	
				</Box>
				<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'}}}>
					<img src={UTurnLogoWhite} height="52" alt="Uturn Logo" />	
				</Box>
				<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', marginRight: '5%' }}}>
					{teamBuildLogoURL && <img src={teamBuildLogoURL} height="52" alt="Team Building Logo" loading="lazy"/>}
				</Box>

				{/* Because of flexGrow 0, this will always be at the end */}
				<Box sx={{ flexGrow: 0 }}>
					{companyLogoURL && <img src={companyLogoURL} height="52" alt="Company Logo" loading="lazy"/>}
				</Box>
			</Toolbar>
		</AppBar>
	);
};
export default Navbar;
