import React, { useEffect, useState } from "react";
import { LoadingView } from "../components/LoadingView"
import Navbar from "../components/Navbar";
import { doc, onSnapshot } from "firebase/firestore";
import { db, functions } from "..";
import { Navigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import Footer from "../components/Footer";

export const WaitingPage = () => {
  const params = useParams();
	const gameId: string = params.gameId!;
  const [isGameStarting, setIsGameStarting] = useState(false);
  const [urlParameter, setUrlParameter] = useState("");

  useEffect(() => {
    async function initializeUrlParameter() {
      try {
        const cachedUrl: string | null = localStorage.getItem("url")
        if (cachedUrl) {
          setUrlParameter(cachedUrl)
        } else {
          throw new Error(`URL Not Found in Waiting Page`)
        }
      } catch (error) {
        if (error instanceof Error) {
          const addError = httpsCallable(functions, 'addError');
          await addError(error.message)
        }
      }
    }
		function setupGameStartListeners() {
			const unsub = onSnapshot(
				doc(db, "Games", gameId, "GameStates", "GameStart"),
				(doc) => {
					if (doc.exists()) {
						setIsGameStarting(doc.data().isGameStarted);
					}
				}
			);
			return unsub;
		}
    initializeUrlParameter();
		setupGameStartListeners();
	}, []);
  
  if (isGameStarting) {
		return (
			<>
				<Navigate to={`/${gameId}/uturn-page/${urlParameter}`} replace={true} />
			</>
		);
	} else {
    return (
      <>
        <Navbar gameId={gameId} isAdmin={false}></Navbar>
        <div className="home">
          <LoadingView isWaitingForHost={true} showLoadingIcon={false}/>
        </div>
        <Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
      </>
    )
  }
}