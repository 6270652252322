import {
	addDoc,
	collection,
	deleteDoc,
	getDocs,
	orderBy,
	query,
	where,
} from "firebase/firestore";
import { db } from "..";
import { prettyPrintFactDetails, prettyPrintFactDetailsArray } from "../utils/PrettyPrintService";
import { logError, logInfo } from "./LoggingService";

export interface FactModel {
	playerId: string;
	playerName: string;
	playerPicture: string;
	fact: string;
	isPremade: boolean
}

export interface FactModelGetDTO {
	playerName: string;
	playerPicture: string;
	fact: string;
}

export interface FactModelForExports {
	playerId: string;
	playerName: string;
	fact: string;
}

export interface FactModelGeneratorDTO {
	factId: string;
	fact: string;
}

export interface FactModelCreateDTO {
	playerId: string;
	playerName: string;
	playerPicture: string;
	facts: Array<string>;
	isPremadeFact: boolean
}

export async function createFacts(
	gameId: string,
	factDetails: FactModelCreateDTO
) {
	try {
		for await (const fact of factDetails.facts) {
			const factItem: FactModel = {
				playerId: factDetails.playerId,
				playerName: factDetails.playerName,
				playerPicture: factDetails.playerPicture,
				fact: fact,
				isPremade: factDetails.isPremadeFact,
			};

			const playerFactsRef = collection(db, "Games", gameId, "Facts");
			addDoc(playerFactsRef, factItem);
			logInfo(
				`Successfully Create Fact with Details: ${prettyPrintFactDetails(factItem)}`
			);
		}
	} catch (error) {
		logError(
			`Firestore could not create facts with details: ${prettyPrintFactDetailsArray(
				factDetails
			)} - ${error}`
		);
		throw new Error(`Firestore could not create facts with details: ${prettyPrintFactDetailsArray(
			factDetails
		)} - ${error}`)
	}
}

export async function getAllFactsNotFromCurrentPlayer(
	gameId: string,
	playerId: string
) {
	try {
		const factsFromOtherPlayers: Array<FactModelGetDTO> = [];
		const factsRef = collection(db, "Games", gameId, "Facts");
		const queryOfFacts = query(factsRef, where("playerId", "!=", playerId));

		const querySnapshot = await getDocs(queryOfFacts);
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerFact: FactModelGetDTO = {
					playerName: document.data().playerName,
					playerPicture: document.data().playerPicture,
					fact: document.data().fact,
				};
				factsFromOtherPlayers.push(playerFact);
			}
		}
		logInfo(`Successfully Filtered Facts Not Belonging to player with id: ${playerId}`)
		return factsFromOtherPlayers;
	} catch (error) {
		logError(
			`Firestore could not Filter Facts Not Belonging to player with id: ${playerId} - ${error}`
		);
		throw new Error(`Firestore could not Filter Facts Not Belonging to player with id: ${playerId} - ${error}`)
	}
}

export async function getAllPremadeFacts(
	gameId: string,
) {
	try {
		const factsFromOtherPlayers: Array<FactModelGetDTO> = [];
		const factsRef = collection(db, "Games", gameId, "Facts");
		const queryOfFacts = query(factsRef, where("isPremade", "==", true));

		const querySnapshot = await getDocs(queryOfFacts);
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerFact: FactModelGetDTO = {
					playerName: document.data().playerName,
					playerPicture: document.data().playerPicture,
					fact: document.data().fact,
				};
				factsFromOtherPlayers.push(playerFact);
			}
		}
		logInfo(`Successfully Filtered Premade Facts Only`)
		return factsFromOtherPlayers;
	} catch (error) {
		logError(
			`Firestore could not Filter premade facts for player - ${error}`
		);
		throw new Error(`Firestore could not Filter Facts Not Belonging to player with id - ${error}`)
	}
}

export async function getAllFactsForExports(gameId: string) {
	try {
		const facts: Array<FactModelForExports> = [];
		const factsRef = collection(db, "Games", gameId, "Facts");

		const querySnapshot = await getDocs(factsRef);
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerFact: FactModelForExports = {
					playerId: document.data().playerId,
					playerName: document.data().playerName,
					fact: document.data().fact,
				};
				facts.push(playerFact);
			}
		}
		logInfo(`Successfully Exported All Facts for gameId: ${gameId}`)
		return facts;
	} catch (error) {
		logError(
			`Firestore could not get all facts`
		);
		throw new Error(`Firestore could not get all facts`)
	}
}

export async function getAllFactsForPlayer(gameId: string, playerId: string) {
	try {
		const facts: Array<FactModelGeneratorDTO> = [];
		const factsCollection = collection(db, "Games", gameId, "Facts");
		const factQuery = query(factsCollection, where("playerId", "==", playerId), orderBy("playerId"));

		const querySnapshot = await getDocs(factQuery);
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				const playerFact: FactModelGeneratorDTO = {
					factId: document.id,
					fact: document.data().fact,
				};
				facts.push(playerFact);
			}
		}
		logInfo(`Successfully All Facts for playerId: ${playerId}`)
		return facts;
	} catch (error) {
		logError(
			`Firestore could not get all facts for playerId: ${playerId}`
		);
		throw new Error(`Firestore could not get all facts for playerId: ${playerId}`)
	}
}


export async function deleteAllFactsInGame(gameId: string) {
	try {
		const factsRef = collection(db, "Games", gameId, "Facts");
		const queryOfFacts = query(factsRef, where("isPremade", "==", false));

		const querySnapshot = await getDocs(queryOfFacts);
		for (const document of querySnapshot.docs) {
			deleteDoc(document.ref);
		}
		logInfo(
			`Firestore Successfully Deleted All of the Facts for gameId: ${gameId}`
		);
	} catch (error) {
		logError(
			`Firestore could not Delete All of the Facts for gameId: ${gameId} - ${error}`
		);
		throw new Error(`Firestore could not Delete All of the Facts for gameId: ${gameId} - ${error}`)
	}
}
