import React, { useContext } from "react";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { GameGenerateDialog } from "../components/dialogs/GameGenerateDialog";
import {
	deleteExpiredGameLinks,
	GameLinkGetDTO,
} from "../services/GameLinkService";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getUserRole } from "../services/UserService";
import { LoadingView } from "../components/LoadingView";
import { LangContext } from "../context/lang";

export const GameGeneratorPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const navigate = useNavigate();

	// Getting URL Parameters
	const params = useParams();
	const gameId: string = params.gameId!;

	const emptyScoreDetails: Array<GameLinkGetDTO> = [];
	const [gameLinkDetails, setGameLinkDetails] =
		useState<Array<GameLinkGetDTO>>(emptyScoreDetails);

	const [isGameGenerationDialogShowing, setIsGameGenerationDialogShowing] =
		useState(false);
	const [hasAccess, setHasAccess] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const handleGameGenerationDialogClose = () => {
		setIsGameGenerationDialogShowing(false);
	};

	const handleGameGenerationDialogOpen = () => {
		setIsGameGenerationDialogShowing(true);
	};

	useEffect(() => {
		// Delete expired game links when page renders and every hour NO AUTO DELETE FOR NOW
		//deleteExpiredGameLinks();
		//const intervalId = setInterval(deleteExpiredGameLinks, 3600000);
		function setupGameLinkListeners() {
			const q = query(
				collection(db, "GameLinks"),
				orderBy("companyName", "asc")
			);
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				const gameLinkDetailsCollection: Array<GameLinkGetDTO> = [];
				querySnapshot.forEach((gameLinkDoc) => {
					const gameLinkDetails: GameLinkGetDTO = {
						companyName: gameLinkDoc.data().companyName,
						gameLink: gameLinkDoc.data().gameLink,
						expirationDate: new Date(
							gameLinkDoc.data().expirationDate.seconds * 1000
						),
					};
					gameLinkDetailsCollection.push(gameLinkDetails);
				});
				setGameLinkDetails(gameLinkDetailsCollection);
			});
			return unsubscribe;
		}

		async function setMonitorAuthState() {
			const auth = getAuth();
			onAuthStateChanged(auth, async (user) => {
				if (!user) {
					navigate(`/login?redirect=/`);
				} else {
					const userRole = await getUserRole(user.uid);
					console.log(userRole);
					setHasAccess(userRole === "super-admin");
					setIsLoggedIn(true);
				}
			});
		}

		setMonitorAuthState();
		setupGameLinkListeners();
	}, []);

	if (hasAccess && isLoggedIn) {
		return (
			<>
				<section className="home">
					<Navbar gameId={gameId} isAdmin={true}></Navbar>
					<h1> {translate("admin-generate-game-header")} </h1>
					<Container sx={{ marginBottom: "20px" }}>
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>
											<strong> {translate("game-generator-company-name")} </strong>
										</TableCell>
										<TableCell align="left">
											<strong> {translate("game-generator-game-link")} </strong>
										</TableCell>
										<TableCell align="left">
											<strong> {translate("game-generator-expiration-date")} </strong>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{gameLinkDetails.map((gameLink) => (
										<TableRow key={gameLink.companyName}>
											<TableCell>{gameLink.companyName}</TableCell>
											<TableCell align="left">{gameLink.gameLink}</TableCell>
											<TableCell align="left">
												{`${gameLink.expirationDate.toDateString()}   ${gameLink.expirationDate.toLocaleTimeString()}`}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Container>
					<div>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								marginTop: "16px",
								marginBottom: "200px",
							}}
						>
							<Button
								variant="contained"
								onClick={handleGameGenerationDialogOpen}
							>
								{translate("admin-generate-game")}
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={deleteExpiredGameLinks}
								style={{ marginTop: "10px" }}
							>
								{translate("game-generator-delete-games")}
							</Button>
						</Box>
					</div>
					<GameGenerateDialog
						open={isGameGenerationDialogShowing}
						onClose={handleGameGenerationDialogClose}
					/>
				</section>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</>
		);
	} else if (!hasAccess && isLoggedIn) {
		return (
			<>
				<section className="home">
					<Navbar gameId={gameId} isAdmin={true}></Navbar>
					<h1>
						{
							translate("game-generator-no-access")
						}
					</h1>
				</section>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</>
		);
	} else {
		return (
			<>
				<section className="home">
					<Navbar gameId={gameId} isAdmin={false}></Navbar>
					<LoadingView isWaitingForHost={false} showLoadingIcon={true}/>
				</section>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</>
		);
	}
};
