import React from "react";
import "./App.scss";
import { Router } from "./Router";

function App() {
	return (
		<>
			<Router />
		</>
	);
}

export default App;
