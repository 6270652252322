import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import { Backdrop } from "@mui/material";

interface LostPageQRProps {
	gameId: string;
	qrHeaderText: string;
	qrInstructions: string
}

export const LostPageQR = (props: LostPageQRProps) => {
    const domain = 'https://u-turn.ca'
    // const domain = 'https://u-turn-development.web.app'

	const [url, setUrl] = useState("")
	const [imageUrl, setImageUrl] = useState("");
	const [previewImage, setPreviewImage] = useState("")

	const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

	useEffect(() => {
		function createURL() {
			setUrl(`${domain}/${props.gameId}/uturn-page/`);
		}
		async function generateQrCodes() {
			const response = await QRCode.toDataURL(url);
			const preview = await QRCode.toDataURL(url, { scale: 18 });
			setImageUrl(response);
			setPreviewImage(preview)
		}

		async function initializePage() {
			try {
				createURL();
				await generateQrCodes();
			} catch (error) {
				console.log(error);
			}
		}

		initializePage();
	}, [url, props.gameId]);

	return (
		<div>
			<div className="qr">
				<h2>{props.qrHeaderText}</h2>
				<p>{props.qrInstructions}</p>
				<img src={imageUrl} alt="img" onClick={handleOpen}/>
				<div> {url} </div>
			</div>
			<Backdrop
        sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
				<img className="qr__preview" src={previewImage} alt="preview-img"/>
      </Backdrop>
		</div>
	);
};
