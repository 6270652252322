import React from "react";
import { PhotoCamera } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface CameraButtonProps {
	isPremadeProfile: boolean
	handlePictureSelection: (e: { target: { files: any } }) => void;
}

export const CameraButton = (props: CameraButtonProps) => {
	
	const iconColour = () => (props.isPremadeProfile ? 'grey' : 'white') 
	
	return (
		<IconButton
			sx={{ color: "white" }}
			aria-label="upload picture"
			component="label"
		>
			<input
				hidden
				accept="image/*"
				id="icon-button-file"
				type="file"
				onChange={props.handlePictureSelection}
			/>
			<PhotoCamera sx={{ color: iconColour(), fontSize: 27 }} />
		</IconButton>
	);
};
