import React, { useContext, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GameLinkCreateDTO, createGameLink, getAllGameIds } from "../../services/GameLinkService";
import { createGame } from "../../services/GameService";
import {
	createGameStartState,
	createGuessingPremadeFactsOnly,
	createNumberOfFactsState,
	createNumberOfMaxTries,
	createVisibleScoreState,
} from "../../services/GameStatesService";
import { LangContext } from "../../context/lang";

interface GameGenerateDialogProps {
	open: boolean;
	onClose: () => void;
}

const alphaNumeric =
	"ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
function makeGameId() {
	let hash = "";
	for (let i = 0; i < 6; i++) {
		const index: number = Math.floor(alphaNumeric.length * Math.random());
		hash = hash + alphaNumeric.charAt(index);
	}
	return hash;
}

export const GameGenerateDialog = (props: GameGenerateDialogProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
  const domain = 'https://u-turn.ca'
//   const domain = 'https://u-turn-development.web.app'

	const emptyGameIds: Array<string> = []
	const [companyName, setCompanyName] = useState("");
	const [gameIds, setGameIds] = useState(emptyGameIds);
	const [errorMessage, setErrorMessage] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [dateValue, setDateValue] = useState<Dayjs | null>(null);

	// Handlers
	function handleTextInput(
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) {
		const currentText = e.target.value;
		setCompanyName(currentText);
	}

	const handleDateChange = (newValue: Dayjs | null) => {
		setErrorMessage("")
		setDateValue(newValue);
	};

	function handleOnClose() {
		setCompanyName("")
		setDateValue(null)
		props.onClose()
	}

	async function handleGenerate() {
		let hash = makeGameId()

		while(gameIds.includes(hash)) {
			hash = makeGameId()
		}

		const url = `${domain}/${hash}`;

		const gameLinkDetails: GameLinkCreateDTO = {
			gameLink: url,
			companyName: companyName,
			expirationDate: dateValue!.toDate(),
			gameId: hash
		};

		try {
			setIsLoading(true)

			// Convert the expirationDate property to a Dayjs instance using the dayjs function
			const expirationDateAsDayjs = dayjs(gameLinkDetails.expirationDate);

			// Check if expiration date is in the future
			if (expirationDateAsDayjs.isBefore(dayjs())) {
				throw new Error(`Date must be bigger than your current time`)
			}
			
			// Create Game
			await createGame(hash);
			await createGameLink(gameLinkDetails);
			await createGameStartState(hash);
			await createVisibleScoreState(hash);
			await createGuessingPremadeFactsOnly(hash)
			await createNumberOfFactsState(hash);
			await createNumberOfMaxTries(hash);
			handleOnClose();
		} catch(error: any) {
			if (error) {
				setErrorMessage(error.message)
			}
		} finally {
			setIsLoading(false)
		}
	}

	// Validation
	function isFormComplete() {
		return companyName && dateValue;
	}

	useEffect(() => {
    async function fetchAllGameIds() {
      const gameIdsResult = await getAllGameIds()
			if (gameIdsResult) {
				setGameIds(gameIdsResult)
			}
    }
    fetchAllGameIds();
	}, []);

	return (
		<>
			<Dialog
				open={props.open}
				onClose={handleOnClose}
				style={{ paddingBottom: "200px" }}
			>
				<DialogTitle>{translate("generate-dialog-title")}</DialogTitle>
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<DialogContentText>
						{translate("gerenate-dialog-text")}
					</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							pt: 0,
						}}
					>
						<TextField
							sx={{
								mb: 3,
							}}
							autoFocus
							margin="dense"
							label="Company Name"
							type="text"
							fullWidth
							variant="outlined"
							onChange={(e) => handleTextInput(e)}
							value={companyName}
						/>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DateTimePicker
								label="Date & Time Experation"
								minDate={dayjs()}
								value={dateValue}
								onChange={handleDateChange}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
						{errorMessage&&<small className='red'>{errorMessage}</small>}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleOnClose}>
						{translate("game-dialog-cancel")}
					</Button>
					<Button disabled={!isFormComplete() || isLoading} onClick={handleGenerate}>
						{translate("game-dialog-generate")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
