import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../context/lang';

interface NavigationDrawerProps {
	gameId: string;
}

export const NavigationDrawer = (navigationDrawerProps: NavigationDrawerProps) => {
  const {
		dispatch: { translate },
	} = useContext(LangContext);
  const navigate = useNavigate();
  const gameId: string = navigationDrawerProps.gameId;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const routes = [
    { text: translate("navigation-drawer-home"),
      path: `/${gameId}/`,
    },
    {
      text: translate("navigation-drawer-game-controls"),
      path: `/${gameId}/adminboard`,
    },
    {
      text: translate("navigation-drawer-fact-creator"),
      path: `/${gameId}/player-facts`,
    },
    {
      text: translate("navigation-drawer-settings"),
      path: `/${gameId}/settings`,
    },
  ]

  function toggleDrawer() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  const DrawerContent = () => (
    <Box sx={{ width: 250 }}>
      <List>
        {routes.map((route) => (
          <ListItem key={route.text} disablePadding>
            <ListItemButton onClick={() => navigate(route.path)}>
              <ListItemText primary={route.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton color="inherit" aria-label="delete" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <DrawerContent/>
      </Drawer>
    </>
  );
}
export default NavigationDrawer;