import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import { LangContext } from "../context/lang";
import { PlayerProfileCreation } from "../components/PlayerProfileCreation";
import { httpsCallable } from "firebase/functions";
import { functions } from "..";
import Navbar from "../components/Navbar";

export const PlayerProfile = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);

	// Getting URL Parameters
	const params = useParams();
	const [searchParams] = useSearchParams();
	const gameId: string = params.gameId!;

	// Rendering States
	const [numberOfFacts, setNumberOfFacts] = useState(0);

	useEffect(() => {
		async function fetchNumberOfFacts() {
			try {
				const urlFactNumber = searchParams.get("factNumber")
				if (urlFactNumber) {
					setNumberOfFacts(parseInt(urlFactNumber))
				} else {
					throw new Error('Fact Number is not defined in the Player Profile URL')
				}
			} catch (error) {
				if (error instanceof Error) {
					const addError = httpsCallable(functions, 'addError');
					await addError(error.message)
				}
			}
		}
		fetchNumberOfFacts()
	}, [gameId, searchParams]);

	return (
		<div className="home">
			<Navbar gameId={gameId} isAdmin={false}></Navbar>
			<section>
				<h1>{translate("create-profile-title")}</h1>
				<PlayerProfileCreation
					gameId={gameId}
					numberOfFacts={numberOfFacts}
					isPremadeProfile={false}
				/>
			</section>
			<Footer
				gameId={gameId}
				cardProgress={null}
				isScoreVisible={null}
				playerId={null}
				isScore={false}
			/>
		</div>
	)
	
};
