import React, { useContext } from "react";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
	Box,
	Button,
	Collapse,
	Container,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
  Checkbox,
  Typography,
  TextField,
  TablePagination,
  Alert,
} from "@mui/material";
import {
  PlayerFactSummary,
  deletePlayerFact,
  deletePlayerFacts,
  deletePlayerProfile,
  initializePlayerFacts,
  updatePlayerName,
  updateFact,
  addFact
} from "../services/FactGenerationService";
import { LoadingView } from "../components/LoadingView";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { FactModel, FactModelGeneratorDTO } from "../services/FactService";
import { blue, green, grey, red } from "@mui/material/colors";
import { LangContext } from "../context/lang";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "..";
import { ConfirmationDialog } from "../components/dialogs/ConfirmationDialog";
import { FactGenerateDialog } from "../components/dialogs/FactGenerateDialog";
import { getAuth, onAuthStateChanged } from "firebase/auth";

interface PlayerFactSummaryProps {
  playerId: string;
	playerName: string;
	playerFacts: Array<FactModelGeneratorDTO>;
	playerPicture: string;
  isPremade: boolean
  handleEditProfileName: (playerId: string, playerName: string) => void
  handleDeleteProfileAction: (playerId: string) => void
  handleAddFact: (playerId: string, fact: FactModel) => void
  handleEditFact: (playerId: string, factId: string, fact: string) => void
  handleDeleteFactAction: (playerId: string, factId: string) => void
  isGameStarted: boolean
}

function stringAvatar(name: string) {
  const nameSplit = name.split(' ')
  const initialValue = '';
  const initials = nameSplit.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue,
  )
  return {
    sx: {
      bgcolor: "#333333",
    },
    children: initials,
  };
}

function Row(props: PlayerFactSummaryProps) {
  const {
		dispatch: { translate },
	} = useContext(LangContext);
  const [open, setOpen] = useState(false);
  const [isAddingFact, setIsAddingFact] = useState(false)
  const [isEditingProfileName, setIsEditingProfileName] = useState(false)
  const [isEditingFact, setIsEditingFact] = useState(false)
  const [editedPlayerName, setEditedPlayerName] = useState('')
  const [addedFact, setAddedFact] = useState('')
  const [editedFact, setEditedFact] = useState('')
  const [editedFactId, setEditedFactId] = useState('')

  async function handleEditProfileName() {
    await props.handleEditProfileName(props.playerId, editedPlayerName)
    setIsEditingProfileName(false)
  }

  async function handleAddFact() {
    const factItem: FactModel = {
      playerId: props.playerId,
      playerName: props.playerName,
      playerPicture: props.playerPicture,
      fact: addedFact,
      isPremade: true
    }
    await props.handleAddFact(props.playerId, factItem)
    setIsAddingFact(false)
    setAddedFact('')
  }

  async function handleEditFact(factId: string) {
    await props.handleEditFact(props.playerId, factId, editedFact)
    setIsEditingFact(false)
    setEditedFactId('')
  }

  function handleStartEditingProfileName() {
    setEditedPlayerName(props.playerName)
    setIsEditingProfileName(true)
  }

  function handleStartEditingFact(factId: string, fact: string) {
    setEditedFactId(factId)
    setEditedFact(fact)
    setIsEditingFact(true)
  }

  function handleStopEditingFact() {
    setEditedFactId('')
    setIsEditingFact(false)
  }

  const isFactInEditMode = (factId: string) => isEditingFact && factId === editedFactId

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box sx={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
            {
              props.playerPicture ?
              <Avatar src={props.playerPicture} /> :
              <Avatar {...stringAvatar(props.playerName)}/>
            }
            {
              isEditingProfileName ?
              <TextField 
                sx={{marginLeft: "12px", marginRight: "12px"}}
                value={editedPlayerName}
                onChange={(e) => setEditedPlayerName(e.target.value)}
              /> :
              <Typography sx={{marginLeft: "12px"}}>{props.playerName}</Typography>
            }
          </Box>
        </TableCell>
        <TableCell component="th" scope="row">
          <Checkbox 
            sx={{
              color: `${blue[800]}!important`,
              '&.Mui-checked': {
                color: blue[600],
              },
            }}
            checked={props.isPremade}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display:'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent:'end'
            }}
          >
            {
              isEditingProfileName ?
              <span>
                <IconButton onClick={handleEditProfileName} >
                  <DoneIcon sx={{color: `${green[700]}!important`}}/>
                </IconButton>
                <IconButton onClick={() => setIsEditingProfileName(false)}>
                  <CloseIcon sx={{color: `${grey[700]}!important`}}/>
                </IconButton>
              </span> :
              <span>
                <IconButton
                  disabled={props.isGameStarted}
                  onClick={handleStartEditingProfileName}
                >
                  <EditIcon/>
                </IconButton>
                <IconButton
                  disabled={props.isGameStarted}
                  onClick={() => props.handleDeleteProfileAction(props.playerId)}
                >
                  <DeleteIcon sx={{color: `${props.isGameStarted ? red[300]: red[700]}!important`}}/>
                </IconButton>
              </span>
            }
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 3 }}>
              <Table aria-label="facts">
                <TableHead>
                  <TableRow>
                    <TableCell>{translate("fact-generator-fact-header")}</TableCell>
                    <TableCell>
                      <Box 
                        sx={{
                          display:'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent:'end'
                          }}
                        >
                        <IconButton
                          onClick={() => setIsAddingFact(true)}
                          disabled={props.isGameStarted}
                        >
                          <AddIcon sx={{color: `${grey[700]}!important`}}/>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    isAddingFact && 
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <TextField 
                          sx={{marginLeft: "12px", marginRight: "12px"}}
                          value={addedFact}
                          onChange={(e) => setAddedFact(e.target.value)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box 
                          sx={{
                            display:'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent:'end'
                          }}
                        >
                          <span>
                            <IconButton onClick={handleAddFact} >
                              <DoneIcon sx={{color: `${green[700]}!important`}}/>
                            </IconButton>
                            <IconButton onClick={() => setIsAddingFact(false)}>
                              <CloseIcon sx={{color: `${grey[700]}!important`}}/>
                            </IconButton>
                          </span> 
                        </Box>
                      </TableCell>
                    </TableRow>
                  }      
                  {props.playerFacts.map((fact) => (
                    <TableRow key={fact.factId}>
                      <TableCell component="th" scope="row">
                      {
                        isFactInEditMode(fact.factId) ?
                        <TextField 
                          sx={{marginLeft: "12px", marginRight: "12px"}}
                          value={editedFact}
                          onChange={(e) => setEditedFact(e.target.value)}
                        /> :
                        <Typography sx={{marginLeft: "12px"}}>{fact.fact}</Typography>
                      }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            display:'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent:'end'
                          }}
                        >
                          {
                            isFactInEditMode(fact.factId) ?
                            <span>
                              <IconButton onClick={() => handleEditFact(fact.factId)} >
                                <DoneIcon sx={{color: `${green[700]}!important`}}/>
                              </IconButton>
                              <IconButton onClick={handleStopEditingFact}>
                                <CloseIcon sx={{color: `${grey[700]}!important`}}/>
                              </IconButton>
                            </span> :
                            <span>
                              <IconButton 
                                disabled={props.isGameStarted}
                                onClick={() => handleStartEditingFact(fact.factId, fact.fact)}
                              >
                                <EditIcon/>
                              </IconButton>
                              <IconButton 
                                disabled={props.isGameStarted}
                                onClick={() => props.handleDeleteFactAction(props.playerId, fact.factId)}
                              >
                                <DeleteIcon sx={{color: `${props.isGameStarted ? red[300] : red[700]}!important`}}/>
                              </IconButton>
                            </span>
                          }
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const FactGeneratorPage = () => {
  const navigate = useNavigate();
  const {
		dispatch: { translate },
	} = useContext(LangContext);
	// Getting URL Parameters
	const params = useParams();
	const gameId: string = params.gameId!;

	const emptyPlayerFactSummaries: Array<PlayerFactSummary> = [];
	const [playerFactSummaries, setPlayerFactSummaries] = 
    useState<Array<PlayerFactSummary>>(emptyPlayerFactSummaries);

	const [isLoading, setIsLoading] = useState(false);
  const [isMakingProfile, setIsMakingProfile] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isGameStarted, setIsGameStarted] = useState(false)
  const [isDeletingProfile, setIsDeletingProfile] = useState(false)
  const [deletedProfileId, setDeletedProfileId] = useState('')
  const [isDeletingFact, setIsDeletingFact] = useState(false)
  const [deletedFactId, setDeletedFactId] = useState('')

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleEditProfileName(playerId: string, playerName: string) {
    await updatePlayerName(gameId, playerId, playerName)
    const clonedPlayerFacts =  structuredClone(playerFactSummaries)
    const index = clonedPlayerFacts.findIndex(
      (clonedPlayerFact: PlayerFactSummary) => clonedPlayerFact.playerId === playerId
    )
    clonedPlayerFacts[index].playerName = playerName
    clonedPlayerFacts.sort(comparePlayerProfiles)
    setPlayerFactSummaries(clonedPlayerFacts)
  }

  async function handleDeleteProfile() {
    await deletePlayerProfile(gameId, deletedProfileId)
    await deletePlayerFacts(gameId, deletedProfileId)
    const clonedPlayerFacts =  structuredClone(playerFactSummaries)
    const index = clonedPlayerFacts.findIndex(
      (clonedPlayerFact: PlayerFactSummary) => clonedPlayerFact.playerId === deletedProfileId
    )
    clonedPlayerFacts.splice(index, 1)
    setPlayerFactSummaries(clonedPlayerFacts)
    setIsDeletingProfile(false)
  }

  async function handleAddFact(playerId: string, fact: FactModel) {
    const factId = await addFact(gameId, fact)
    const clonedPlayerFacts =  structuredClone(playerFactSummaries)
    const profileIndex = clonedPlayerFacts.findIndex(
      (clonedPlayerFact: PlayerFactSummary) => clonedPlayerFact.playerId === playerId
    )
    clonedPlayerFacts[profileIndex].playerFacts.push({
      factId: factId,
      fact: fact.fact,
    })
    clonedPlayerFacts[profileIndex].playerFacts.sort(comparePlayerFacts)
    setPlayerFactSummaries(clonedPlayerFacts)
  }

  async function handleEditFact(playerId: string, factId: string, fact: string) {
    await updateFact(gameId, factId, fact)
    const clonedPlayerFacts =  structuredClone(playerFactSummaries)
    const profileIndex = clonedPlayerFacts.findIndex(
      (clonedPlayerFact: PlayerFactSummary) => clonedPlayerFact.playerId === playerId
    )
    const factIndex = clonedPlayerFacts[profileIndex].playerFacts.findIndex(
      (playerFact: FactModelGeneratorDTO) => playerFact.factId === factId
    )
    clonedPlayerFacts[profileIndex].playerFacts[factIndex].fact = fact
    clonedPlayerFacts[profileIndex].playerFacts.sort(comparePlayerFacts)
    setPlayerFactSummaries(clonedPlayerFacts)
  }

  async function handleDeleteFact() {
    await deletePlayerFact(gameId, deletedFactId)
    const clonedPlayerFacts =  structuredClone(playerFactSummaries)
    const profileIndex = clonedPlayerFacts.findIndex(
      (clonedPlayerFact: PlayerFactSummary) => clonedPlayerFact.playerId === deletedProfileId
    )
    const factIndex = clonedPlayerFacts[profileIndex].playerFacts.findIndex(
      (playerFact: FactModelGeneratorDTO) => playerFact.factId === deletedFactId
    )
    clonedPlayerFacts[profileIndex].playerFacts.splice(factIndex, 1)
    setPlayerFactSummaries(clonedPlayerFacts)
    setIsDeletingFact(false)
  }

  const handleDeleteProfileClose = () => {
    setIsDeletingProfile(false)
  }

  const handleDeleteProfileOpen = (profileId: string) => {
    setDeletedProfileId(profileId)
    setIsDeletingProfile(true)
  }

  const handleStoreAndClose = (fact: PlayerFactSummary) => {
    const playerFactSummariesCopy: Array<PlayerFactSummary> = JSON.parse(JSON.stringify(playerFactSummaries))
    playerFactSummariesCopy.push(fact)
    playerFactSummariesCopy.sort(comparePlayerProfiles)
    setPlayerFactSummaries(playerFactSummariesCopy)
    setIsMakingProfile(false)
  }

  const handleFactGenerateClose = () => {
    setIsMakingProfile(false)
  }

  const handleFactGenerateOpen = () => {
    setIsMakingProfile(true)
  }

  const handleDeleteFactClose = () => {
    setIsDeletingFact(false)
  }

  const handleDeleteFactOpen = (playerId: string, factId: string) => {
    setDeletedProfileId(playerId)
    setDeletedFactId(factId)
    setIsDeletingFact(true)
  }

  function comparePlayerProfiles(fact1: PlayerFactSummary, fact2: PlayerFactSummary) {
    if(fact1.playerName > fact2.playerName) {
      return 1
    }
    if (fact1.playerName < fact2.playerName) {
      return -1
    }
    return 0
  }

  function comparePlayerFacts(fact1: FactModelGeneratorDTO, fact2: FactModelGeneratorDTO) {
    if(fact1.fact > fact2.fact) {
      return 1
    }
    if (fact1.fact < fact2.fact) {
      return -1
    }
    return 0
  }

	useEffect(() => {
    async function setMonitorAuthState() {
			const auth = getAuth();
			onAuthStateChanged(auth, (user) => {
				if (!user) {
					navigate(`/login?redirect=/${gameId}`);
				}
			});
		}
    async function fetchAllPlayableFacts() {
      try {
        setIsLoading(true)
        const { playerProfiles } = await initializePlayerFacts(gameId)
        setPlayerFactSummaries(playerProfiles)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    function setupGameStartListeners() {
			const unsub = onSnapshot(
				doc(db, "Games", gameId, "GameStates", "GameStart"),
				(doc) => {
					if (doc.exists()) {
						setIsGameStarted(doc.data().isGameStarted);
            console.log(doc.data().isGameStarted)
					}
				}
			);
			return unsub;
		}
    setMonitorAuthState();
		setupGameStartListeners();
    fetchAllPlayableFacts();
	}, []);

  if (isLoading) {
    return (
			<>
				<section className="home">
					<Navbar gameId={gameId} isAdmin={false}></Navbar>
					<LoadingView isWaitingForHost={false} showLoadingIcon={true}/>
				</section>
				<Footer
					gameId={gameId}
					cardProgress={null}
					isScoreVisible={null}
					playerId={null}
					isScore={false}
				/>
			</>
		);
  }
  else {
    return (
      <>
        <section className="home">
          <Navbar gameId={gameId} isAdmin={true}></Navbar>
          <h1> {translate("fact-generator-header")} </h1>
          <Container sx={{ marginBottom: "200px" }}>
            <Box 
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginBottom: "8px"
              }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                disabled={isGameStarted}
                onClick={handleFactGenerateOpen}
              >
                {translate("fact-generator-add-profile")}
              </Button>
            </Box>
            {isGameStarted && 
              <Alert 
                icon={<InfoIcon fontSize="inherit" />}
                severity="info"
                sx={{marginBottom: "12px"}}
              >
                {translate("fact-generator-info-message")}
              </Alert>
            }
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                      <TableCell>
                        <strong> {translate("fact-generator-player-name")} </strong>
                      </TableCell>
                      <TableCell>
                        <strong> {translate("fact-generator-premade-profile")} </strong>
                      </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playerFactSummaries
                    .slice((page) * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((playerFactSummary) => (
                      <Row 
                        key={playerFactSummary.playerId}
                        playerId={playerFactSummary.playerId}
                        playerName={playerFactSummary.playerName}
                        playerPicture={playerFactSummary.playerPicture}
                        playerFacts={playerFactSummary.playerFacts}
                        isPremade={playerFactSummary.isPremade}
                        handleEditProfileName={handleEditProfileName}
                        handleDeleteProfileAction={handleDeleteProfileOpen}
                        handleAddFact={handleAddFact}
                        handleEditFact={handleEditFact}
                        handleDeleteFactAction={handleDeleteFactOpen}
                        isGameStarted={isGameStarted}
                      />
                      )
                    )
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                labelRowsPerPage={translate("fact-generator-row-label")}
                rowsPerPageOptions={[10, 25, 50]}
                count={playerFactSummaries.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Container>
        </section>
        <Footer
          gameId={gameId}
          cardProgress={null}
          isScoreVisible={null}
          playerId={null}
          isScore={false}
        />
        <ConfirmationDialog
          titleText={translate("delete-profile-dialog-title")}
          bodyText={translate("delete-profile-dialog-body-text")}
          confirmationText={translate("delete-profile-dialog-confirmation-text")}
          confirmButtonText={translate("delete-profile-dialog-confirmation-text")}
          cancelButtonText={translate("game-dialog-cancel")}
          open={isDeletingProfile}
          onClose={handleDeleteProfileClose}
          onConfirm={handleDeleteProfile}
        />
        <ConfirmationDialog
          titleText={translate("delete-fact-dialog-title")}
          bodyText={translate("delete-fact-dialog-body-text")}
          confirmationText={translate("delete-fact-dialog-confirmation-text")}
          confirmButtonText={translate("delete-fact-dialog-confirmation-text")}
          cancelButtonText={translate("game-dialog-cancel")}
          open={isDeletingFact}
          onClose={handleDeleteFactClose}
          onConfirm={handleDeleteFact}
        />
        <FactGenerateDialog
          gameId={gameId}
          open={isMakingProfile}
          onClose={handleFactGenerateClose}
          onConfirm={handleStoreAndClose}
        />
      </>
    );
  }
}

