import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PhotoCamera } from "@mui/icons-material";
import { LangContext } from "../../context/lang";

interface PhotoPromptDialogProps {
	open: boolean;
	onReturn: () => void;
	onProceedWithoutSelfie: () => void;
}

export const PhotoPromptDialog = (props: PhotoPromptDialogProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const [hasSubmittedFact, setHasSubmittedFact] = useState(false);

	function handleSubmission() {
		props.onReturn();
		setHasSubmittedFact(true);
		props.onProceedWithoutSelfie();
	}
	return (
		<>
			<Dialog open={props.open} onClose={props.onReturn}>
				<DialogTitle>{translate("photo-prompt-title")}</DialogTitle>
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<DialogContentText>
						{translate("photo-prompt-content")}
					</DialogContentText>
					<PhotoCamera sx={{ color: "black", fontSize: 27 }} />
				</DialogContent>
				<DialogActions>
					<Button disabled={hasSubmittedFact} onClick={handleSubmission}>
						{translate("photo-prompt-continue")}
					</Button>
					<Button onClick={props.onReturn}>
						{translate("photo-prompt-return")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
