import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import { getGuessingPremadeFactsOnly, getNumberOfFactsState } from "../../services/GameStatesService";
import { Backdrop } from "@mui/material";

interface PlayerLinkQRProps {
	gameId: string;
	qrHeaderText: string;
	qrInstructions: string
}

export const PlayerLinkQR = (props: PlayerLinkQRProps) => {
    const domain = 'https://u-turn.ca'
    // const domain = 'https://u-turn-development.web.app'

	const [url, setUrl] = useState("")
	const [imageUrl, setImageUrl] = useState("");
	const [previewImage, setPreviewImage] = useState("")

	const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

	useEffect(() => {
		async function createUrl() {
			const isGuessingPremadeFactsOnly = await getGuessingPremadeFactsOnly(props.gameId)
			let numberOfFactsPerPlayer = 0
			if (!isGuessingPremadeFactsOnly) {
				numberOfFactsPerPlayer = await getNumberOfFactsState(props.gameId);
			}
			const link = `${domain}/${props.gameId}/player-profile/?factNumber=${numberOfFactsPerPlayer}`
			setUrl(link)
			await generateQrCodes(link)
		}

		async function generateQrCodes(link: string) {
			const response = await QRCode.toDataURL(link);
			const preview = await QRCode.toDataURL(link, { scale: 18 });
			setImageUrl(response);
			setPreviewImage(preview)
		}

		async function initializePage() {
			try {
				await createUrl();
			} catch (error) {
				console.log(error);
			}
		}

		initializePage();
	}, [props.gameId]);

	return (
		<div>
			<div className="qr">
				<h2>{props.qrHeaderText}</h2>
				<p>{props.qrInstructions}</p>
				{imageUrl && <img src={imageUrl} alt="img" onClick={handleOpen}/>}
				{url && <div> {url} </div>}
			</div>
			<Backdrop
        sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
				<img className="qr__preview" src={previewImage} alt="preview-img"/>
      </Backdrop>
		</div>
	);
};
