import {
	addDoc,
	collection,
	deleteDoc,
	getDocs,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "..";
import { prettyPrintScoreDetailsDTO } from "../utils/PrettyPrintService";
import { logError, logInfo } from "./LoggingService";

export interface ScoreCreateDTO {
	playerId: string;
	playerName: string;
	playerPicture: string;
	score: number;
}

export interface ScoreGetDTO {
	playerName: string;
	playerPicture: string;
	score: number;
}

export interface ScoreWithIdGetDTO {
	playerId: string;
	playerName: string;
	playerPicture: string;
	score: number;
}

export async function createScore(
	gameId: string,
	scoreDetails: ScoreCreateDTO
) {
	try {
		const scores = collection(db, "Games", gameId, "Scores");
		await addDoc(scores, {
			playerPicture: scoreDetails.playerPicture,
			playerName: scoreDetails.playerName,
			playerId: scoreDetails.playerId,
			score: scoreDetails.score,
		});
		logInfo(
			`Successfully Created score entry with score details: ${prettyPrintScoreDetailsDTO(
				scoreDetails
			)}`
		);
	} catch (error) {
		logError(
			`Firestore could not create profile with player details: ${prettyPrintScoreDetailsDTO(
				scoreDetails
			)} - ${error}`
		);
		throw new Error(
			`Firestore could not create profile with player details: ${prettyPrintScoreDetailsDTO(
				scoreDetails
			)} - ${error}`
		);
	}
}

export async function updateScore(gameId: string, id: string, score: number) {
	try {
		const scores = collection(db, "Games", gameId, "Scores");
		const queryOfScores = query(scores, where("playerId", "==", id));
		const querySnapshot = await getDocs(queryOfScores);
		for (const document of querySnapshot.docs) {
			if (document.data()) {
				await updateDoc(document.ref, {
					score: score,
				});
			}
		}
		logInfo(`Successfully Updated Score for player Id ${id}`)
	} catch (error) {
		logError(
			`Firestore could not update Score for player Id ${id} - ${error}`
		);
		throw new Error(
			`Firestore could not update Score for player Id ${id} - ${error}`
		);
	}
}

export async function getAllScores(gameId: string) {
	try {
		const scoresRef = collection(db, "Games", gameId, "Scores");
		const sortedScoresQuery = query(scoresRef, orderBy("score", "desc"));
		const scoreDocs = await getDocs(sortedScoresQuery);
		const scores: Array<ScoreWithIdGetDTO> = [];
		for (const scoreDoc of scoreDocs.docs) {
			if (scoreDoc.data()) {
				const scoreDetails: ScoreWithIdGetDTO = {
					playerId: scoreDoc.data().playerId,
					playerName: scoreDoc.data().playerName,
					playerPicture: scoreDoc.data().playerPicture,
					score: scoreDoc.data().score,
				};
				scores.push(scoreDetails);
			}
		}
		logInfo(`Successfully Got all Score Details`)
		return scores;
	} catch (error) {
		logError(
			`Firestore could not Get All Score Details - ${error}`
		);
		throw new Error(`Firestore could not Get All Score Details - ${error}`);
	}
}

export async function deleteAllScoresInGame(gameId: string) {
	try {
		const scoresRef = collection(db, "Games", gameId, "Scores");
		const querySnapshot = await getDocs(scoresRef);
		for (const document of querySnapshot.docs) {
			deleteDoc(document.ref);
		}
		logInfo(`Successfully Deleted all Score Details for gameId: ${gameId}`);
	} catch (error) {
		logError(
			`Firestore failed to retrieve all the documents for gameId: ${gameId} - ${error}`
		);
		throw new Error(
			`Firestore failed to retrieve all the documents for gameId: ${gameId} - ${error}`
		);
	}
}
