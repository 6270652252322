import React from "react";
import { QuestionMark, Close } from "@mui/icons-material";
import { Avatar, Box, Grid, Paper, styled } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { FactModelGetDTO } from "../services/FactService";

export interface FactPosition {
	rowIndex: number;
	columnIndex: number;
}

export interface CardSpotProgress {
	numOfTries: number;
	cardState: string;
}

interface UTurnCardItem {
	factItem: FactModelGetDTO;
	cardItemProgress: CardSpotProgress;
	rowIndex: number;
	columnIndex: number;
	logoColour: string;
	onCardItemAnswerSubmission: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
	onCardItemViewSummary: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
}

interface UTurnCardProps {
	facts: FactModelGetDTO[][];
	cardProgress: CardSpotProgress[][];
	logoColour: string;
	onCardItemAnswerSubmission: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
	onCardItemViewSummary: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
}

interface UTurnCardRow {
	factsRowData: FactModelGetDTO[];
	cardProgressRow: CardSpotProgress[];
	logoColour: string;
	rowIndex: number;
	onCardItemAnswerSubmission: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
	onCardItemViewSummary: (
		factDetails: FactModelGetDTO,
		cardPosition: FactPosition
	) => void;
}

const ResizablePaper = styled(Paper)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		padding: 1,
		height: "110px",
		fontSize: "0.8rem",
	},
	[theme.breakpoints.up("md")]: {
		padding: 2,
		height: "200px",
	},
}));

const ResizableAvatar = styled(Avatar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "30px",
		height: "30px",
		borderStyle: "solid",
		borderWidth: "5px",
	},
	[theme.breakpoints.up("md")]: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "60px",
		height: "60px",
		borderStyle: "solid",
		borderWidth: "10px",
	},
}));

const ResizableAvatarCorrect = styled(Avatar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "55px",
		height: "55px",
	},
	[theme.breakpoints.up("md")]: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "90px",
		height: "90px",
	},
}));

function FactItem(props: UTurnCardItem) {
	const itemPosition: FactPosition = {
		rowIndex: props.rowIndex,
		columnIndex: props.columnIndex,
	};

	if (props.cardItemProgress.cardState === "correct") {
		return (
			<ResizablePaper
				sx={{
					backgroundColor: "#C1E1C1",
					textAlign: "center",
					overflow: "hidden",
				}}
				onClick={() =>
					props.onCardItemViewSummary(props.factItem, itemPosition)
				}
			>
				<Box
					sx={{
						width: "100%",
						height: "30%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
					}}
				>
					<span id="card_text">
						<strong>{props.factItem.playerName}</strong>
					</span>
				</Box>
				<Box
					sx={{
						width: "100%",
						height: "70%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
					}}
				>
					<ResizableAvatarCorrect src={props.factItem.playerPicture} />
				</Box>
			</ResizablePaper>
		);
	}

	if (props.cardItemProgress.cardState === "incorrect") {
		return (
			<ResizablePaper
				sx={{
					backgroundColor: red[100],
					textAlign: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						width: "100%",
						height: "50%",
					}}
				>
					<ResizableAvatar
						sx={{
							bgcolor: red[800],
						}}
					>
						<Close sx={{ height: "70%", width: "70%" }} />
					</ResizableAvatar>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						width: "100%",
						height: "50%",
					}}
				>
					<span id="card_text">{props.factItem.fact}</span>
				</Box>
			</ResizablePaper>
		);
	}

	return (
		<ResizablePaper
			sx={{
				backgroundColor: "white",
				textAlign: "center",
			}}
			onClick={() =>
				props.onCardItemAnswerSubmission(props.factItem, itemPosition)
			}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
					width: "100%",
					height: "50%",
				}}
			>
				<ResizableAvatar
					sx={{
						bgcolor: blue[500],
						borderColor: props.logoColour
					}}
				>
					<QuestionMark sx={{ height: "70%", width: "70%" }} />
				</ResizableAvatar>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
					width: "100%",
					height: "50%",
				}}
			>
				<span id="card_text">{props.factItem.fact}</span>
			</Box>
		</ResizablePaper>
	);
}

function FactRow(props: UTurnCardRow) {
	return (
		<Grid container item spacing={1}>
			{props.factsRowData.map((fact, index) => (
				<Grid key={`Fact${index}`} item xs={2.4}>
					<FactItem
						factItem={fact}
						cardItemProgress={props.cardProgressRow[index]}
						logoColour={props.logoColour}
						rowIndex={props.rowIndex}
						columnIndex={index}
						onCardItemAnswerSubmission={props.onCardItemAnswerSubmission}
						onCardItemViewSummary={props.onCardItemViewSummary}
					/>
				</Grid>
			))}
		</Grid>
	);
}

export const UTurnCard = (props: UTurnCardProps) => {
	return (
		<>
			<Grid container sx={{ marginTop: 2, marginBottom: 2 }} spacing={1}>
				{props.facts.map((factsRowData, index) => (
					<FactRow
						key={`Row${index}`}
						rowIndex={index}
						factsRowData={factsRowData}
						cardProgressRow={props.cardProgress[index]}
						logoColour={props.logoColour}
						onCardItemAnswerSubmission={props.onCardItemAnswerSubmission}
						onCardItemViewSummary={props.onCardItemViewSummary}
					/>
				))}
			</Grid>
		</>
	);
};
