import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "..";

export async function createGameStartState(gameId: string) {
	try {
		const gameStartDocRef = doc(db, "Games", gameId, "GameStates", "GameStart");
		await setDoc(gameStartDocRef, {
			isGameStarted: false,
		});
		console.log(
			`Successfully created isGameStarted state for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not create Game Start State for gameId: ${gameId} \n ${error}`
		);
	}
}

export async function updateGameStartState(
	gameId: string,
	isGameStarted: boolean
) {
	try {
		const gameStartDocRef = doc(db, "Games", gameId, "GameStates", "GameStart");
		await updateDoc(gameStartDocRef, {
			isGameStarted: isGameStarted,
		});
		console.log(
			`Successfully updated isGameStarted to ${isGameStarted} for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not set GameState to ${isGameStarted} for gameId: ${gameId}\n ${error}`
		);
	}
}

export async function getGameStartState(gameId: string) {
	try {
		const gameStartDocRef = doc(db, "Games", gameId, "GameStates", "GameStart");
		const gameStartDocSnapshot = await getDoc(gameStartDocRef);

		if (gameStartDocSnapshot.exists()) {
			return gameStartDocSnapshot.data().isGameStarted;
		}
	} catch (error) {
		console.log(`Firestore could not get Game Start State\n ${error}`);
	}
}

export async function createVisibleScoreState(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"VisibleScore"
		);
		await setDoc(gameStartDocRef, {
			isScoreVisible: true,
		});
		console.log(
			`Successfully created isScoreVisible state for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not create Visible Score State for gameId: ${gameId} \n ${error}`
		);
	}
}

export async function updateVisibleScoreState(
	gameId: string,
	isScoreVisible: boolean
) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"VisibleScore"
		);
		await updateDoc(gameStartDocRef, {
			isScoreVisible: isScoreVisible,
		});
		console.log(`Successfully updated isScoreVisible to ${isScoreVisible}`);
	} catch (error) {
		console.log(
			`Firestore could not set Visible Score State to ${isScoreVisible}\n ${error}`
		);
	}
}

export async function getVisibleScoreState(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"VisibleScore"
		);
		const gameStartDocSnapshot = await getDoc(gameStartDocRef);

		if (gameStartDocSnapshot.exists()) {
			return gameStartDocSnapshot.data().isScoreVisible;
		}
	} catch (error) {
		console.log(`Firestore could not get VisibleScore State\n ${error}`);
	}
}

export async function createGuessingPremadeFactsOnly(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"GuessingPremadeFactsOnly"
		);
		await setDoc(gameStartDocRef, {
			isGuessingPremadeFactsOnly: false,
		});
		console.log(
			`Successfully created isGuessingPremadeFactsOnly state for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not create Guessing Premade Facts Only for gameId: ${gameId} \n ${error}`
		);
	}
}

export async function updateGuessingPremadeFactsOnly(
	gameId: string,
	isGuessingPremadeFactsOnly: boolean
) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"GuessingPremadeFactsOnly"
		);
		await updateDoc(gameStartDocRef, {
			isGuessingPremadeFactsOnly: isGuessingPremadeFactsOnly,
		});
		console.log(`Successfully updated isGuessingPremadeFactsOnly to ${isGuessingPremadeFactsOnly}`);
	} catch (error) {
		console.log(
			`Firestore could not set Guessing Premade Facts Only field to ${isGuessingPremadeFactsOnly}\n ${error}`
		);
	}
}

export async function getGuessingPremadeFactsOnly(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"GuessingPremadeFactsOnly"
		);
		const gameStartDocSnapshot = await getDoc(gameStartDocRef);

		if (gameStartDocSnapshot.exists()) {
			return gameStartDocSnapshot.data().isGuessingPremadeFactsOnly;
		}
	} catch (error) {
		console.log(`Firestore could not get Guessing Premade Facts Field State\n ${error}`);
	}
}

export async function createNumberOfFactsState(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfFacts"
		);
		await setDoc(gameStartDocRef, {
			numberOfFactsPerPlayer: -1,
		});
		console.log(`Successfully Created NumberOfFacts for gameId: ${gameId}`);
	} catch (error) {
		console.log(
			`Firestore could not Create NumberOfFacts State for gameId: ${gameId}\n ${error}`
		);
	}
}

export async function updateNumberOfFactsState(
	gameId: string,
	numberOfFactsPerPlayer: number
) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfFacts"
		);
		await updateDoc(gameStartDocRef, {
			numberOfFactsPerPlayer: numberOfFactsPerPlayer,
		});
		console.log(
			`Successfully updated NumberOfFacts to ${numberOfFactsPerPlayer} for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not set NumberOfFacts State to ${numberOfFactsPerPlayer} for gameId: ${gameId}\n ${error}`
		);
	}
}

export async function getNumberOfFactsState(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfFacts"
		);
		const gameStartDocSnapshot = await getDoc(gameStartDocRef);

		if (gameStartDocSnapshot.exists()) {
			return gameStartDocSnapshot.data().numberOfFactsPerPlayer;
		}
	} catch (error) {
		console.log(`Firestore could not get NumberOfFacts State\n ${error}`);
	}
}

export async function createNumberOfMaxTries(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfMaxTries"
		);
		await setDoc(gameStartDocRef, {
			numberOfMaxTries: 0,
		});
		console.log(`Successfully Created NumberOfMaxTries for gameId: ${gameId}`);
	} catch (error) {
		console.log(
			`Firestore could not Create NumberOfMaxTries State for gameId: ${gameId}\n ${error}`
		);
	}
}

export async function updateNumberOfMaxTries(
	gameId: string,
	numberOfMaxTries: number
) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfMaxTries"
		);
		await updateDoc(gameStartDocRef, {
			numberOfMaxTries: numberOfMaxTries,
		});
		console.log(
			`Successfully updated NumberOfFacts to ${numberOfMaxTries} for gameId: ${gameId}`
		);
	} catch (error) {
		console.log(
			`Firestore could not set NumberOfFacts State to ${numberOfMaxTries} for gameId: ${gameId}\n ${error}`
		);
	}
}

export async function getNumberOfMaxTries(gameId: string) {
	try {
		const gameStartDocRef = doc(
			db,
			"Games",
			gameId,
			"GameStates",
			"NumberOfMaxTries"
		);
		const gameStartDocSnapshot = await getDoc(gameStartDocRef);

		if (gameStartDocSnapshot.exists()) {
			return gameStartDocSnapshot.data().numberOfMaxTries;
		}
	} catch (error) {
		console.log(`Firestore could not get NumberOfFacts State\n ${error}`);
	}
}


export async function getBackgroundColourGameState(gameId: string) {
	try {
		let backgroundColour = "";
		const settings = doc(db, "Games", gameId, "GameStates", "BackgroundColour");
		const backgroundColourSnapshot = await getDoc(settings);

		if (backgroundColourSnapshot.exists()) {
			console.log("Document data:", backgroundColourSnapshot.data());
			if (backgroundColourSnapshot.data()) {
				backgroundColour = backgroundColourSnapshot.data().backgroundColour;
			}
		}
		return backgroundColour;
	} catch (error) {
		console.log(error);
	}
}

export async function setBackgroundColourGameState(gameId: string, colour: string) {
	try {
		const settings = doc(db, "Games", gameId, "GameStates", "BackgroundColour");
		await setDoc(settings, {
			backgroundColour: colour,
		});
		console.log("Succesfully Completed Background Colour Update");
	} catch (error) {
		console.log(error);
	}
}

export async function getLogoColourGameState(gameId: string) {
	try {
		let logoColour = "";
		const settings = doc(db, "Games", gameId, "GameStates", "LogoColour");
		const logoColourSnapshot = await getDoc(settings);

		if (logoColourSnapshot.exists()) {
			console.log("Document data:", logoColourSnapshot.data());
			if (logoColourSnapshot.data()) {
				logoColour = logoColourSnapshot.data().logoColour;
			}
		}
		return logoColour;
	} catch (error) {
		console.log(error);
	}
}

export async function setLogoColourGameState(gameId: string, colour: string) {
	try {
		const settings = doc(db, "Games", gameId, "GameStates", "LogoColour");
		await setDoc(settings, {
			logoColour: colour,
		});
		console.log("Succesfully Completed Logo Colour Update");
	} catch (error) {
		console.log(error);
	}
}