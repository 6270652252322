import React, { useContext, useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Autocomplete,
	TextField,
	DialogActions,
	Button,
	Paper,
	Avatar,
} from "@mui/material";
import { PlayerGetDTO } from "../../services/PlayerProfileService";
import { FactModelGetDTO } from "../../services/FactService";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { green, red } from "@mui/material/colors";
import { LangContext } from "../../context/lang";

interface CorrectAnswerDialog {
	open: boolean;
	onClose: () => void;
}

interface IncorrectAnswerDialog {
	numberOfTriesLeft: number;
	hasMaxTries: boolean;
	open: boolean;
	onClose: () => void;
	onTryAgain: () => void;
}

interface GuessingDialogProps {
	selectedFact: FactModelGetDTO;
	numberOfTriesLeft: number;
	hasMaxTries: boolean;
	open: boolean;
	onClose: () => void;
	factOwners: Array<PlayerGetDTO>;
	onSubmit: (factOwnerName: string | null) => void;
}

interface SubmitAnswerDialogProps {
	selectedFact: FactModelGetDTO;
	numberOfTriesLeft: number;
	hasMaxTries: boolean;
	open: boolean;
	onClose: () => void;
	factOwners: Array<PlayerGetDTO>;
	onSubmitCorrectAnswer: () => void;
	onSubmitIncorrectAnswer: () => void;
}

const CorrectGuessDialog = (props: CorrectAnswerDialog) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			fullWidth={true}
			maxWidth={"sm"}
		>
			<DialogTitle className="dialog_title">
				{translate("guessfact-dialog-correct")}
			</DialogTitle>
			<DialogContent>
				<Paper elevation={3} className="dialog">
					<Avatar
						sx={{
							width: "60px",
							height: "60px",
							marginBottom: 2,
							backgroundColor: green[500],
						}}
						className="dialog_content"
					>
						<SentimentSatisfiedAltIcon sx={{ height: "70%", width: "70%" }} />
					</Avatar>
					<span className="dialog_content">
						{translate("guessfact-dialog-congrats")}
					</span>
				</Paper>
			</DialogContent>
			<DialogActions className="dialog_actions">
				<Button color="success" onClick={props.onClose}>
					{translate("guessfact-dialog-close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const IncorrectGuessDialog = (props: IncorrectAnswerDialog) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			fullWidth={true}
			maxWidth={"sm"}
		>
			<DialogTitle className="dialog_title">
				{translate("guessfact-dialog-incorrect")}
			</DialogTitle>
			<DialogContent>
				<Paper elevation={3} className="dialog">
					<Avatar
						sx={{
							width: "60px",
							height: "60px",
							marginBottom: 2,
							backgroundColor: red[500],
						}}
						className="dialog_content"
					>
						<SentimentVeryDissatisfiedIcon
							sx={{ height: "70%", width: "70%" }}
						/>
					</Avatar>
					<span className="dialog_content">
						{translate("guessfact-dialog-incorrect")}
					</span>
				</Paper>
			</DialogContent>
			<DialogActions className="dialog_actions">
				<Button color="error" onClick={props.onClose}>
					{translate("guessfact-dialog-close")}
				</Button>
				{props.numberOfTriesLeft <= 0 && props.hasMaxTries ? null : (
					<Button color="error" onClick={props.onTryAgain}>
						{translate("guessfact-dialog-tryagain")}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

const GuessingForm = (props: GuessingDialogProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const [factOwnerGuess, setFactOwnerGuess] = useState<string | null>(null);

	function isGuessEmpty() {
		if (factOwnerGuess === null) {
			return true;
		} else {
			return false;
		}
	}

	function getTriesLeftText() {
		return props.numberOfTriesLeft <= 1
			? translate("guessfact-dialog-try-left")
			: translate("guessfact-dialog-tries-left");
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			fullWidth={true}
			maxWidth={"sm"}
		>
			<DialogTitle>{translate("guessfact-dialog-who")}</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ textAlign: "center", margin: 2 }}>
					{props.selectedFact.fact}
				</DialogContentText>
				{props.hasMaxTries ? (
					<DialogContentText sx={{ textAlign: "center", margin: 2 }}>
						{`${props.numberOfTriesLeft} ${getTriesLeftText()}`}
					</DialogContentText>
				) : null}

				<Autocomplete
					onChange={(event: any, newValue: string | null) => {
						setFactOwnerGuess(newValue);
					}}
					options={props.factOwners.map(
						(factOwnerDetails) => factOwnerDetails.name
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={translate("leaderboard-name")}
						/>
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>
					{translate("game-dialog-cancel")}
				</Button>
				<Button
					onClick={() => props.onSubmit(factOwnerGuess)}
					disabled={isGuessEmpty()}
				>
					{translate("guessfact-dialog-submit")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export const GuessFactOwnerDialog = (props: SubmitAnswerDialogProps) => {
	const [isCorrect, setIsCorrect] = useState(false);
	const [isIncorrect, setIsIncorrect] = useState(false);

	function isGuessCorrect(factOwnerName: string | null) {
		if (factOwnerName == props.selectedFact.playerName) {
			props.onSubmitCorrectAnswer();
			setIsCorrect(true);
		} else {
			props.onSubmitIncorrectAnswer();
			setIsIncorrect(true);
		}
	}

	function handleCloseDialog() {
		setIsCorrect(false);
		setIsIncorrect(false);
		props.onClose();
	}

	function handleTryAgain() {
		setIsCorrect(false);
		setIsIncorrect(false);
	}

	if (isCorrect) {
		return (
			<CorrectGuessDialog
				open={props.open}
				onClose={handleCloseDialog}
			/>
		);
	} else if (isIncorrect) {
		return (
			<IncorrectGuessDialog
				numberOfTriesLeft={props.numberOfTriesLeft}
				hasMaxTries={props.hasMaxTries}
				open={props.open}
				onClose={handleCloseDialog}
				onTryAgain={handleTryAgain}
			/>
		);
	} else {
		return (
			<GuessingForm
				selectedFact={props.selectedFact}
				numberOfTriesLeft={props.numberOfTriesLeft}
				hasMaxTries={props.hasMaxTries}
				open={props.open}
				factOwners={props.factOwners}
				onClose={handleCloseDialog}
				onSubmit={isGuessCorrect}
			/>
		);
	}
};
