import React, { useState, useEffect, useContext } from "react";
import {
	Avatar,
	Box,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { getAllScores, ScoreGetDTO } from "../services/ScoreService";
import { LangContext } from "../context/lang";

interface LeaderboardProps {
	gameId: string;
	isRealtime: boolean;
}

export const Leaderboard = (props: LeaderboardProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const emptyScoreDetails: Array<ScoreGetDTO> = [];
	const [scoreDetails, setScoreDetails] =
		useState<Array<ScoreGetDTO>>(emptyScoreDetails);
	const [totalScore, setTotalScore] = useState(0);

	useEffect(() => {
		async function initializeScoreDetails() {
			const scoreDetails = await getAllScores(props.gameId);
			if (scoreDetails) {
				setScoreDetails(scoreDetails);
			}
		}

		function setupScoreListeners() {
			const q = query(
				collection(db, "Games", props.gameId, "Scores"),
				orderBy("score", "desc")
			);
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				const scoreDetailsCollection: Array<ScoreGetDTO> = [];
				let totalScore = 0;
				querySnapshot.forEach((scoreDoc) => {
					const scoreDetails: ScoreGetDTO = {
						playerName: scoreDoc.data().playerName,
						playerPicture: scoreDoc.data().playerPicture,
						score: scoreDoc.data().score,
					};
					totalScore = totalScore + scoreDoc.data().score;
					scoreDetailsCollection.push(scoreDetails);
				});
				setTotalScore(totalScore);
				setScoreDetails(scoreDetailsCollection);
			});
			return unsubscribe;
		}
		if (props.isRealtime) {
			setupScoreListeners();
		} else {
			initializeScoreDetails();
		}
	}, [props.isRealtime, props.gameId]);

	return (
		<Container sx={{ marginBottom: "100px" }}>
			<h1>{translate("leaderboard-title")}</h1>
			{props.isRealtime ? (
				<Box sx={{ color: "#ffffff", fontSize: "20px", margin: 2 }}>
					{`${translate("leaderboard-total-score")} ${totalScore}`}
				</Box>
			) : null}
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>
								<strong>{translate("leaderboard-rank")}</strong>
							</TableCell>
							<TableCell align="left">
								<strong>{translate("leaderboard-name")}</strong>
							</TableCell>
							<TableCell align="left">
								<strong>{translate("leaderboard-score")}</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{scoreDetails.map((scoreDetail, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell align="left">
									<Box sx={{ display: "flex", alignItems: "center" }}>
										{!props.isRealtime ? (
											<Avatar
												sx={{ marginRight: 2 }}
												src={scoreDetail.playerPicture}
											></Avatar>
										) : null}
										<span id="leaderboard_text">{scoreDetail.playerName}</span>
									</Box>
								</TableCell>
								<TableCell align="left">{scoreDetail.score}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
};
