import React, { useContext } from "react";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { FactModelGetDTO } from "../../services/FactService";
import { LangContext } from "../../context/lang";

interface FactSummaryDialogProps {
	open: boolean;
	onClose: () => void;
	selectedFact: FactModelGetDTO;
}

export const FactSummaryDialog = (props: FactSummaryDialogProps) => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			fullWidth={true}
			maxWidth={"sm"}
		>
			<DialogTitle>{translate("fact-dialog-title")}</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ textAlign: "center", marginBottom: 2 }}>
					<strong>{props.selectedFact.playerName}</strong>
				</DialogContentText>
				<Box sx={{ width: "100%" }}>
					<Avatar
						sx={{ marginLeft: "auto", marginRight: "auto" }}
						src={props.selectedFact.playerPicture}
					></Avatar>
				</Box>
				<DialogContentText sx={{ textAlign: "center", marginTop: 2 }}>
					{props.selectedFact.fact}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>
					{translate("fact-dialog-close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
