import React from "react";
import { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { deleteAllScoresInGame } from "../services/ScoreService";
import { deleteAllPlayerProfilesInGame } from "../services/PlayerProfileService";
import { deleteAllFactsInGame } from "../services/FactService";
import { ConfirmationDialog } from "../components/dialogs/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import {
	getGameStartState,
	updateGameStartState,
	updateNumberOfFactsState,
} from "../services/GameStatesService";
import { Button } from "@mui/material";
import { LangContext } from "../context/lang";
import { getAuth, onAuthStateChanged } from "firebase/auth";


export const StartPage = () => {
	const {
		dispatch: { translate },
	} = useContext(LangContext);
	const navigate = useNavigate();

	// Getting URL Parameters
	const params = useParams();
	const gameId: string = params.gameId!;
	const [isGameResetDialogShowing, setIsGameResetDialogShowing] =
		useState(false);

	const handleGameResetDialogOpen = async () => {
		const hasGameStarted = await getGameStartState(gameId);
		if (hasGameStarted) {
			setIsGameResetDialogShowing(true);
		} else {
			handleResetGame();
		}
	};

	const handleGameResetDialogClose = () => {
		setIsGameResetDialogShowing(false);
	};

	function handleResetGame() {
		deleteAllScoresInGame(gameId);
		deleteAllPlayerProfilesInGame(gameId);
		deleteAllFactsInGame(gameId);
		updateGameStartState(gameId, false);
		updateNumberOfFactsState(gameId, -1);
		setIsGameResetDialogShowing(false);
		navigate(`/${gameId}/number-players`);
	}

	function handleSettingsClick() {
		navigate(`/${gameId}/settings`);
	}

	useEffect(() => {
		async function setMonitorAuthState() {
			const auth = getAuth();
			onAuthStateChanged(auth, (user) => {
				if (!user) {
					navigate(`/login?redirect=/${gameId}`);
				}
			});
		}
		setMonitorAuthState();
	}, [navigate, gameId]);

	return (
		<>
			<Navbar gameId={gameId} isAdmin={true}></Navbar>
			<section className="home">
				<h1>{translate("admin-title")}</h1>
				<div>
					<Button
						sx={{ marginRight: 2 }}
						variant="contained"
						onClick={handleGameResetDialogOpen}
					>
						{translate("admin-start-game")}
					</Button>
					<Button variant="contained" onClick={handleSettingsClick}>
						{translate("admin-settings")}
					</Button>
				</div>
				<ConfirmationDialog
					titleText={translate("game-dialog-title")}
					bodyText={translate("game-dialog-reset-text")}
					confirmationText={translate("game-dialog-reset")}
					confirmButtonText={translate("game-dialog-reset")}
					cancelButtonText={translate("game-dialog-cancel")}
					open={isGameResetDialogShowing}
					onClose={handleGameResetDialogClose}
					onConfirm={handleResetGame}
				/>
			</section>
			<Footer
				gameId={gameId}
				cardProgress={null}
				isScoreVisible={null}
				playerId={null}
				isScore={false}
			/>
		</>
	);
};
